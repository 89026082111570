import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../atoms/Container';
import GatsbyImage from 'gatsby-image';
import FadeUpAnimation from '../molecules/FadeUpAnimation';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-content: start;
  margin-bottom: 4rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 6rem;
  }
`;

const ImageHolder = styled.div`
  @media( ${props => props.theme.breakpoints.md} ) {
    &:nth-child(3n + 1) { 
      grid-column: 1/6; 
      margin-top: 25%;
    }
    &:nth-child(3n + 2) { 
      grid-column: 7/13; 
    }
    &:nth-child(3n) { 
      grid-column: 5/11;
      margin-top: 20%;
    }
  }
  p { 
    color: ${props => props.theme.colours.green}; 
    margin-top: 1.5rem;
  }
`;

function NewsGridImages( { images } ) {
  return (
    <Container>
      <Holder>
        {images && images.map( ( image, i ) =>
          <ImageHolder key={i}>
            <FadeUpAnimation>
              <GatsbyImage fluid={image.image.fluid} alt={image.image.alt}/>
              {image.image.copyright && <p>{image.image.copyright}</p>}
            </FadeUpAnimation>
          </ImageHolder>
        )}
      </Holder>
    </Container>
  )
}

NewsGridImages.propTypes = {
  images: PropTypes.array.isRequired,
};

export default NewsGridImages;
