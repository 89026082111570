import React, {useState} from 'react';
import styled from 'styled-components';
import {PropTypes} from 'prop-types';
import GalleryArrows from '../atoms/GalleryArrows';
import Container from '../atoms/Container';
import ProjectPageSlide from "../molecules/ProjectPageSlide";
import {useSwipeable} from "react-swipeable";

const Holder = styled.div`
  color: ${props => props.theme.colours.grey};
  background-color: ${props => props.theme.colours.black};
  padding: 5rem 0;
  margin-bottom: 3rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 5rem 0 3rem 0;
    margin-bottom: 6rem;
  }

  svg path, svg line {
    stroke: ${props => props.theme.colours.grey};
  }
`;

const Inner = styled.div`
  position: relative;
  h2 {
    margin-bottom: 2rem;
    @media( ${props => props.theme.breakpoints.md} ) {
      position: absolute;
      top: 0;
      left: 0;
      margin-bottom: 0;
    }
  }
`;

const SlideHolder = styled.div`
  position: relative;
`;

const Controls = styled.div`
  display: flex;
`;

function Benefits({slides, heading}) {
  const [current, setCurrent] = useState(0);
  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrent( current === slides.length - 1 ? 0 : current + 1),
    onSwipedRight: () => setCurrent(current === 0 ? slides.length - 1 : current -1),
  });

  return (
    <Holder>
      <Container>
        <Inner>
          <h2 className="small-sans-uppercase">{heading}</h2>
          <SlideHolder {...handlers}>
            {slides && slides.map((slide, i) =>
              <ProjectPageSlide key={i} slide={slide} show={i === current} i={i} />
            )}
          </SlideHolder>
        </Inner>
        <Controls><GalleryArrows clickHandler={(i) => setCurrent(i)} images={slides} current={current} /></Controls>
      </Container>
    </Holder>
  )
}

Benefits.propTypes = {
  slides: PropTypes.array.isRequired,
  heading: PropTypes.string,
};

export default Benefits;
