import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';


const Inner = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  
  &.step-appear,
  &.step-enter {
    opacity: 0;
  }
  &.step-appear-active, &.step-appear-done,
  &.step-enter-active, &.step-enter-done {
    opacity: 1;
    transition: opacity 1s;
  }
  
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  h3 {
    margin: 0 0 1.5rem 0;
  }
`;

const Number = styled.p`
  margin: 0 !important;
  line-height: 1.1;
`;

function Step( { number, title, text, show } ) {
  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear
      exit={false}
      in={show}
      timeout={1000}
      classNames="step">
      <Inner>
        <Number className="h1">{number}</Number>
        <h3 className="h1 sans">{title}</h3>
        <p>{text}</p>
      </Inner>
    </CSSTransition>
  )
}

Step.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
};

export default Step;
