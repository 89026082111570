import React, { useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import GalleryArrows from '../atoms/GalleryArrows';
import Benefit from '../molecules/Benefit';
import Container from '../atoms/Container';
import {useSwipeable} from "react-swipeable";

const Holder = styled.div`
  color: ${props => props.theme.colours.grey};
  background-color: ${props => props.theme.colours.gold};
  padding: 5rem 0;
  svg path, svg line {
    stroke: ${props => props.theme.colours.grey};
  }
`;

const Inner = styled.div`
  position: relative;
`;

const Controls = styled.div`
  display: flex;
`;

function Benefits( { benefits, heading } ) {
  const [ current, setCurrent ] = useState( 0 );
  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrent( current === benefits.length - 1 ? 0 : current + 1),
    onSwipedRight: () => setCurrent(current === 0 ? benefits.length - 1 : current -1),
  });

  return (
    <Holder>
      <Container>
        <h2 className="small-sans-uppercase">{heading}</h2>
        <Inner {...handlers}>
          {benefits && benefits.map( ( benefit, i ) =>
            <Benefit key={i} benefit={benefit} show={i === current} i={i}/>
          )}
        </Inner>
        <Controls><GalleryArrows clickHandler={( i ) => setCurrent( i )} images={benefits} current={current}/></Controls>
      </Container>
    </Holder>
  )
}

Benefits.propTypes = {
  benefits: PropTypes.array.isRequired,
  heading: PropTypes.string,
};

export default Benefits;
