import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../atoms/Container';
import GatsbyImage from 'gatsby-image';
import FadeUpAnimation from '../molecules/FadeUpAnimation';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-content: start;
  margin-bottom: 4rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 5rem;
  }
`;

const ImageHolder = styled.div`
  @media( ${props => props.theme.breakpoints.md} ) {
    &:nth-child(3n + 1) { 
      grid-column: 6/13; 
      margin-bottom: 30%;
    }
    &.portrait:nth-child(3n + 1) {
      grid-column: 8/13;
      margin-bottom: 30%;
    }
    &:nth-child(3n + 2) { 
      grid-column: 1/6; 
    }
    &:nth-child(3n) { 
      grid-column: 8/13;
      margin-top: 100%;
      margin-bottom: 40%;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  p { 
    color: ${props => props.theme.colours.green}; 
    margin-top: 1.5rem;
  }
`;

function ProjectGridImages( { images } ) {
  return (
    <Container>
      <Holder>
        {images && images.map( ( image, i ) =>
          <ImageHolder key={i} className={image.image.fluid.aspectRatio > 1 ? 'landscape' : 'portrait'}>
            <FadeUpAnimation>
              <GatsbyImage fluid={image.image.fluid} alt={image.image.alt}/>
              {image.image.alt && <p>{image.image.alt}</p>}
            </FadeUpAnimation>
          </ImageHolder>
        )}
      </Holder>
    </Container>
  )
}

ProjectGridImages.propTypes = {
  images: PropTypes.array.isRequired,
};

export default ProjectGridImages;
