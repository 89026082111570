import * as React from 'react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'

import linkResolver from './src/utils/linkResolver'
import PageTemplate from './src/templates/Page'
import NewsTemplate from './src/templates/News'
import ProjectTemplate from './src/templates/Project'
import ApproachTemplate from './src/pages/our-approach'
import AboutTemplate from './src/pages/about-us'
import ProjectsTemplate from './src/pages/index'
import HomeTemplate from './src/pages/index'
import FaqsTemplate from './src/pages/faqs'
import ContactTemplate from './src/pages/contact'
import OfficesTemplate from './src/pages/offices'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          'news_post': NewsTemplate,
          'project': ProjectTemplate,
          'our_approach': ApproachTemplate,
          'about_us': AboutTemplate,
          'contact': ContactTemplate,
          'our_projects': ProjectsTemplate,
          'homepage': HomeTemplate,
          'faqs': FaqsTemplate,
          'page': PageTemplate,
          'new_page': OfficesTemplate,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)