import React from 'react';
import styled from 'styled-components';

const Holder = styled.div`
  height: 1.8rem;
  svg {
    height: 100%;
    width: auto;
    display: block;
  }
`;

function Logo() {
  return (
    <Holder>
      <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 1019 208.1" xmlSpace="preserve">
        <path className="st0" d="M969.7,157.4c-16.8,0-26.8-14.5-26.8-33.3V98.9c0.2-24.1,12.9-33.7,27.3-33.7c16.8,0,28.7,17.2,28.7,45.7
	C998.8,136.8,989.9,157.4,969.7,157.4 M975.9,52.8c-16.3,0-28.5,9.4-33,27.3V53.2l-32.6,8v3.2l3.2,0.2c7.8,0.7,11,4.4,11,11.7v113.4
	c0,7.3-3.9,10.8-13.8,12.2v3.2h52.1v-3.2c-14-0.9-20-4.4-20-12.2v-36c8.3,7.1,18.6,9.4,27.8,9.4c25.5,0,48.4-18.6,48.4-56
	C1019,74.3,1001.8,52.8,975.9,52.8 M856.6,157.4c-18.1,0-29.1-17.9-29.1-49.3c0-31.2,11-49.1,29.1-49.1s28.9,17.9,28.9,49.1
	C885.5,139.5,874.8,157.4,856.6,157.4 M856.6,163.1c29.4,0,49.1-22.3,49.1-55.1c0-32.6-19.7-54.8-49.1-54.8s-49.3,22.3-49.3,54.8
	C807.3,140.9,827.3,163.1,856.6,163.1 M786.8,144.6V0l-32.6,8.3v3.2l3.2,0.2c7.8,0.7,10.8,4.1,10.8,11.5v121.4
	c0,7.3-3.7,10.8-13.8,12.2v3.2h46.1v-3.2C790.5,155.4,786.8,151.9,786.8,144.6 M703.3,59c15.4,0,25.7,12.4,23.2,33.7h-51.9
	C677.2,72.7,687.7,59,703.3,59 M743.5,127.6c-4.6,11.5-15.6,22-31.4,22c-24.3,0-38.6-19.3-37.9-49.1v-2.1h71.6
	c3.4-28.2-12.9-45.2-39.2-45.2c-30.1,0-51.6,21.1-51.6,56.5c0,31.4,19.5,53.5,48.2,53.5c22.7,0,38.8-13.3,43.1-34.9L743.5,127.6z
	 M623.7,56.7v3.2c12.4,0.7,15.6,4.8,11.5,14.5L609.7,137l-25.2-63.6c-3.4-9.2-0.7-13.1,10.3-13.5v-3.2h-46.6v3.2
	c7.6,0.5,11.9,4.1,15.6,12.6l37.4,90.6h3.2l36.7-88.3c4.1-9.9,9-14.2,16.1-14.9v-3.2H623.7z M502.9,59c15.4,0,25.7,12.4,23.2,33.7
	h-51.9C476.7,72.7,487.3,59,502.9,59 M543,127.6c-4.6,11.5-15.6,22-31.4,22c-24.3,0-38.5-19.3-37.9-49.1v-2.1h71.6
	c3.4-28.2-12.8-45.2-39.2-45.2c-30.1,0-51.6,21.1-51.6,56.5c0,31.4,19.5,53.5,48.2,53.5c22.7,0,38.8-13.3,43.1-34.9L543,127.6z
	 M354.9,153.5c-14.5,0-18.6-3.4-18.6-13.5V13.1h20.4c38.6,0,63.8,28.2,63.8,70.2c0,41.8-22.5,70.2-56.5,70.2L354.9,153.5z
	 M295,159.9h69.1c47.3,0,78.7-30.7,78.7-76.6s-33-76.6-82.2-76.6H295v3.2c14,0.9,20.7,3.7,20.7,17v112.7c0,13.8-6.7,16.3-20.7,17.2
	V159.9z M265.9,163.4c8.5,0,14.9-6.2,14.9-14.7c0-8.3-6.4-14.2-14.9-14.2c-8.7,0-14.9,6-14.9,14.2
	C251,157.2,257.2,163.4,265.9,163.4 M193.3,157.4c-18.1,0-29.1-17.9-29.1-49.3c0-31.2,11-49.1,29.1-49.1s28.9,17.9,28.9,49.1
	C222.2,139.5,211.4,157.4,193.3,157.4 M193.3,163.1c29.4,0,49.1-22.3,49.1-55.1c0-32.6-19.7-54.8-49.1-54.8s-49.3,22.3-49.3,54.8
	C143.9,140.9,163.9,163.1,193.3,163.1 M118.4,47.5h3.2l3-31.2C111.3,7.8,92.5,3.2,73.9,3.2C31.7,3.2,0,35.3,0,82.8
	c0.2,47,29.6,80.5,72.7,80.5c29.4,0,53.9-18.8,61-51.9l-3.2-0.7c-7.8,23.2-26.8,36.5-49.8,36.5c-33.5,0-58.1-28-58.1-67.2
	C22.5,36.3,42.9,9.6,74.6,9.6C97.5,9.6,115,22.9,118.4,47.5"/>
        <path className="st1" d="M537.2,382.4c0.8,0,1.5-0.7,1.5-1.5s-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5S536.4,382.4,537.2,382.4z"/>
      </svg>
    </Holder>
  )
}

export default Logo;
