import React, {useState} from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import Container from '../atoms/Container';
import MenuIcon from "../atoms/MenuIcon";
import {Link} from "gatsby";
import Logo from "../atoms/Logo";

const TopBG = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 4.8rem;
  z-index: 10;
  background-color: ${props => props.theme.colours.white};
`;

const TopNav = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 12;
  
  .inner {
    padding: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  button.icon {
    svg {
      width: auto;
      height: 1.2rem;
    }
  }
`;

const LogoHolder = styled.div`
  a { display: block; }
  svg path {
    fill: ${props => props.theme.colours.green};
    transition: fill 0.5s;
  }
`;

function Header() {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <>
      <TopBG/>
      <Navigation show={navOpen} clickHandler={(value) => setNavOpen(value)} />
      <TopNav>
        <Container>
          <div className="inner">
            <LogoHolder>
              <Link to="/" onClick={() => setNavOpen(false)}>
                <Logo/>
              </Link>
            </LogoHolder>
            <button className="icon" onClick={() => setNavOpen(!navOpen)}>
              <MenuIcon navOpen={navOpen} />
            </button>
          </div>
        </Container>
      </TopNav>
    </>
  )
}

export default Header
