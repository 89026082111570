import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const Holder = styled.div`
  input[type="submit"] {
    margin-top: 3rem;
    width: 100%;
    @media( ${props => props.theme.breakpoints.sm} ) { 
      width: auto;  
    }
  }
`;

const Message = styled.div`

`;

const validationSchema = Yup.object().shape( {
  name: Yup.string()
  .required( 'Name is Required' ),
  email: Yup.string()
  .email( 'Enter a Valid Email' )
  .required( 'Email is Required' ),
} );

function SubscribeForm() {

  const [ data, setData ] = useState( null );

  if ( data ) {
    return (
      <Message>
        <p dangerouslySetInnerHTML={{ __html: data.msg }}/>
      </Message>
    )
  }

  return (
    <Formik
      initialValues={{ name: '', email: '' }}
      validationSchema={validationSchema}
      onSubmit={( values, { setSubmitting } ) => {
        setTimeout( () => {
          setSubmitting( false );
          addToMailchimp( values.email )
          .then( data => {
            setData( data );
          } )

        }, 400 );
      }}
    >
      {( { isSubmitting } ) => (
        <Form>
          <Holder>
            <div className="fieldset">
              <label htmlFor="name" className="label">
                Your name
              </label>
              <Field className="input" type="text" name="name" placeholder="Your name*"/>
              <ErrorMessage
                className="errorMsg"
                name="name"
                component="small"
              />
            </div>
            <div className="fieldset">
              <label htmlFor="email" className="label">
                Your email
              </label>
              <Field className="input" type="text" name="email" placeholder="Your email*"/>
              <ErrorMessage
                className="errorMsg"
                name="email"
                component="small"
              />
            </div>
            <div>
              <input
                name="submit"
                type="submit"
                disabled={isSubmitting}
                value="Submit"
                className="button"
              />
            </div>
          </Holder>
        </Form>
      )}
    </Formik>
  )
}

export default SubscribeForm;
