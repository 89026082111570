import React from 'react';
import Seo from '../components/molecules/Seo';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PageHeroImage from '../components/organisms/PageHeroImage';
import { RichText } from 'prismic-reactjs';
import TwoColumn from '../components/organisms/TwoColumn';
import Container from '../components/atoms/Container';
import Sustainability from '../components/organisms/Sustainability';
import ProcessLong from '../components/organisms/ProcessLong';
import PageTitle from "../components/atoms/PageTitle";
import Benefits from "../components/organisms/Benefits";
import Breadcrumbs from "../components/molecules/Breadcrumbs";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";


const Holder = styled.div`
  background-color: ${props => props.theme.colours.grey};
`;

const Intro = styled.div`
  .two-column-holder {
    align-items: start;
  }
`;


function ApproachPage( { data } ) {
  const {
    hero,
    strapline,
    page_title,
    intro_text,
    process_heading,
    process,
    benefits_heading,
    benefits,
    sustainability_image,
    sustainability_opportunities,
    sustainability_text,
    sustainability_heading,
  } = data.prismicOurApproach.data;
  return (
    <>
      <Seo title={page_title ? page_title.text : 'Our Approach'}/>
      <PageHeroImage image={hero}/>
      <Holder>
        <PageTitle>{page_title ? page_title.text : 'Our Approach'}</PageTitle>
        <Intro>
          <TwoColumn>
            <div>
              <RichText render={strapline.raw}/>
            </div>
            <div>
              <RichText render={intro_text.raw}/>
            </div>
          </TwoColumn>
        </Intro>
        <Container>
          <ProcessLong steps={process} heading={process_heading}/>
        </Container>
        <Benefits benefits={benefits} heading={benefits_heading.text}/>
        <Sustainability image={sustainability_image} heading={sustainability_heading} text={sustainability_text} opportunities={sustainability_opportunities}/>
        <Breadcrumbs color="black" bgColor="green">
          {page_title ? page_title.text : 'Our Approach'}
        </Breadcrumbs>
      </Holder>
    </>
  )
};

export default withPrismicPreview(ApproachPage);

export const pageQuery = graphql`
    query {
        prismicOurApproach {
            _previewable
            data {
                hero {
                    alt
                    fluid(maxWidth: 4000) {
                        ...GatsbyPrismicImageFluid_noBase64
                    }
                }
                page_title {
                    text
                }
                strapline {
                    raw
                }
                intro_text {
                    raw
                }
                process_heading {
                  text
                  raw
                }
                process {
                    step {
                        document {
                            ... on PrismicProcessStep {
                                id
                                data {
                                    title {
                                        text
                                    }
                                    long_text {
                                        raw
                                    }
                                }
                            }
                        }
                    }
                }
                benefits_heading {
                  text
                }
                benefits {
                    heading {
                        text
                    }
                    text {
                        raw
                    }
                }
                sustainability_image {
                    alt
                    fluid(maxWidth: 4000) {
                        ...GatsbyPrismicImageFluid_noBase64
                    }
                }
                sustainability_opportunities {
                    opportunity {
                        text
                    }
                }
                sustainability_heading {
                    raw
                }
                sustainability_text {
                    raw
                }
            }
        }
    }
`;
