import React from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import SubscribeForm from '../molecules/SubscribeForm';
import {graphql, useStaticQuery} from "gatsby";
import PrismicRichText from "../atoms/PrismicRichText";
import {useMergePrismicPreviewData} from "gatsby-plugin-prismic-previews";

const Holder = styled.div`
  background-color: ${props => props.theme.colours.white};
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  color: ${props => props.theme.colours.green};
  padding: 5rem 0;
  @media( ${props => props.theme.breakpoints.md} ) { 
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    padding: 4rem 0;
  }
  @media( ${props => props.theme.breakpoints.lg} ) { 
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;
  }
`;

const Text = styled.div`
  margin-bottom: 1rem;
  @media( ${props => props.theme.breakpoints.lg} ) { 
    grid-column: 1/3;
    margin-bottom: 0;
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

const FormHolder = styled.div`
  @media( ${props => props.theme.breakpoints.lg} ) { 
    grid-column: 4/7;
  }
`;

function SubscribeHolder() {

  const staticData = useStaticQuery(graphql`
    query subscribeQuery {
      prismicGlobalSettings {
          _previewable
        data {
          subscribe_text {
            raw
          }
        }
      }
    }
  `)

  const { data } = useMergePrismicPreviewData(staticData)

  return (
    <Holder>
      <Container>
        <Inner>
          <Text>
            <PrismicRichText render={data.prismicGlobalSettings.data.subscribe_text.raw}/>
          </Text>
          <FormHolder>
            <SubscribeForm/>
          </FormHolder>
        </Inner>
      </Container>
    </Holder>
  )
}

export default SubscribeHolder;
