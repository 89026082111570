import React, { useState } from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import Step from '../molecules/Step';
import 'intersection-observer';
import { useInView } from 'react-intersection-observer';
import Arrow from '../../assets/backarrow.inline.svg';
import ProcessAnimation from '../atoms/ProcessAnimation';
import { animationBreaks } from '../../data/global';
import PropTypes from 'prop-types';
import {useSwipeable} from "react-swipeable";

const Holder = styled.div`
  color: ${props => props.theme.colours.white};
  background-color: ${props => props.theme.colours.black};
  padding: 5rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 4rem 0 5rem 0;
  }
  h2.small-sans-uppercase {
    @media( ${props => props.theme.breakpoints.md} ) {
      width: 45%;
    }
  }
`;

const StepHolder = styled.div`
  position: relative;
`;


const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: dense;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    align-items: center;
  }
`;

const AnimationHolder = styled.div`
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-column: 2/3;
  }
`;

const Controls = styled.div`
  display: flex;
  margin-top: 2rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    margin-top: 0;
  }
  a { text-decoration: underline; }
`;

const Arrows = styled.div`
  display: flex;
  button {
    svg {
      width: 1.8rem;
      height: auto;
      display: block;
      line, path { stroke: ${props => props.theme.colours.white}; }
    }
    &:last-child svg {
      transform: rotate(180deg);
      margin-left: 1rem;
    }
  }
`;

function ProcessShort({steps, heading}) {
  const [ currentStep, setCurrentStep ] = useState( 1 );
  const [ segment, setSegment ] = useState( [ animationBreaks[ 0 ], animationBreaks[ 1 ] ] );

  const { ref, inView } = useInView( {
    /* Optional options */
    threshold: 0,
  } );

  const backHandler = ( currentStep ) => {
    let newStep = Math.max( currentStep - 1, 1 );
    let newSegment = [ animationBreaks[ currentStep ], animationBreaks[ newStep ] ];
    setCurrentStep( newStep );
    setSegment( newSegment );
    // console.log( `we are animating from step ${currentStep} ( ${newSegment[0]} ) to step
    // ${newStep} ( ${newSegment[1]} );
  };

  const forwardHandler = ( currentStep ) => {
    let newStep = Math.min( currentStep + 1, 5 );
    let newSegment = [ animationBreaks[ currentStep ], animationBreaks[ newStep ] ];
    setCurrentStep( newStep );
    setSegment( newSegment );
    // console.log( `we are animating from step ${currentStep} ( ${newSegment[0]} ) to step
    // ${newStep} ( ${newSegment[1]} );
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => forwardHandler(currentStep),
    onSwipedRight: () => backHandler(currentStep),
  });

  return (
    <Holder>
      <Container>
        <h2 className="small-sans-uppercase">{heading}</h2>
        <Grid {...handlers}>
          <AnimationHolder>
            <ProcessAnimation long={false} segment={segment} play={inView}/>
          </AnimationHolder>
          <StepHolder ref={ref}>
            {steps.map( ( step, i ) =>
              <Step
                key={step.step.document.data.short_title.text}
                show={currentStep - 1 === i}
                number={`0${i + 1}`}
                title={step.step.document.data.short_title.text}
                text={step.step.document.data.short_text.text}/>
            )}
          </StepHolder>
        </Grid>
        <Controls>
          {/*<p><Link to="/our-approach">Learn More</Link></p>*/}
          <Arrows>
            <button disabled={currentStep === 1} className="icon" onClick={() => backHandler( currentStep )}>
              <Arrow/>
            </button>
            <button disabled={currentStep === 5} className="icon" onClick={() => forwardHandler( currentStep )}>
              <Arrow/>
            </button>
          </Arrows>
        </Controls>
      </Container>
    </Holder>
  )
}

ProcessShort.propTypes = {
  steps: PropTypes.array.isRequired,
  heading: PropTypes.string,
};

export default ProcessShort;
