import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';
import Container from '../atoms/Container';

const Holder = styled.div`
  background-color: ${props => props.theme.colours.gold};
  padding: 3rem 0;
  margin-bottom: 3rem;
  @media( ${props => props.theme.breakpoints.md} ) { 
    padding: 4rem 0;
  }
  .title {
    margin-bottom: 2rem;
  }
`;

const Paragraph = styled.div`
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid;
  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
  p {
    ${props => props.theme.fluidType( 4 )}; 
    font-family: "Teodor", serif;
    line-height: 1.2;
    @media( ${props => props.theme.breakpoints.sm} ) { 
      ${props => props.theme.fluidType( 6 )}; 
      line-height: 1.1;
    }
    strong { 
      font-weight: normal;
      font-family: "FKGroteskNeue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      ${props => props.theme.fluidType( 3.5 )};
      @media( ${props => props.theme.breakpoints.sm} ) { 
        ${props => props.theme.fluidType( 5.5 )}; 
      }
    }
  }
`;

function ProjectNumbers( { title, paragraphs } ) {
  if(paragraphs.length < 1) return null;
  return (
    <Holder>
      <Container>
        {title && <div className="title small-sans-uppercase">
          <RichText render={title.raw}/>
        </div>}
        {paragraphs && paragraphs.map( ( paragraph, i ) =>
          <Paragraph key={i}>
            <RichText render={paragraph.numbers_paragraph.raw}/>
          </Paragraph>
        )}
      </Container>
    </Holder>
  )
}

ProjectNumbers.propTypes = {
  paragraphs: PropTypes.array.isRequired,
  title: PropTypes.object
};

export default ProjectNumbers;
