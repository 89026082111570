import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from '../components/molecules/Header';

import GlobalStyles from '../components/atoms/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import { theme } from '../utils/styling';
import Footer from '../components/organisms/Footer';
import SubscribeHolder from '../components/organisms/SubscribeHolder';

class Layout extends Component {

  render() {
    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyles/>
          <Header/>
          <main>{this.props.children}</main>
          {this.props.location.pathname !== '/contact' && <SubscribeHolder/>}
          <Footer/>
        </>
      </ThemeProvider>
    )
  }
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
