import React, { useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import GalleryArrows from '../atoms/GalleryArrows';
import Testimonial from '../molecules/Testimonial';
import Container from '../atoms/Container';
import {useSwipeable} from "react-swipeable";

const Holder = styled.div`
  color: ${props => props.theme.colours.black};
  background-color: ${props => props.theme.colours.gold};
  padding: 5rem 0;
  svg path, svg line {
    stroke: ${props => props.theme.colours.black};
  }
`;

const Inner = styled.div`
  position: relative;
`;

const Controls = styled.div`
  display: flex;
`;

function Testimonials( { testimonials, heading } ) {
  const [ current, setCurrent ] = useState( 0 );
  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrent( current === testimonials.length - 1 ? 0 : current + 1),
    onSwipedRight: () => setCurrent(current === 0 ? testimonials.length - 1 : current -1),
  });

  return (
    <Holder>
      <Container>
        <h2 className="small-sans-uppercase">{heading}</h2>
        <Inner {...handlers}>
          {testimonials && testimonials.map( ( testimonial, i ) =>
            <Testimonial key={i} testimonial={testimonial} show={i === current}/>
          )}
        </Inner>
        <Controls><GalleryArrows clickHandler={( i ) => setCurrent( i )} images={testimonials} current={current}/></Controls>
      </Container>
    </Holder>
  )
}

Testimonials.propTypes = {
  testimonials: PropTypes.array.isRequired,
  heading: PropTypes.string,
};

export default Testimonials;
