import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Holder = styled.div`
  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 1rem;
  left: 0;
  display: ${props => props.hideMobile ? 'none' : 'flex'};
  justify-content: center;
  @media( ${props => props.theme.breakpoints.md} ) { 
    bottom: 2rem;
    display: ${props => props.hideDesktop ? 'none' : 'flex'};
  }
`;

const Button = styled.button`
  width: 0.6rem;
  height: 0.6rem;
  border: 1px solid;
  color: ${props => props.theme.colours.white};
  background-color: ${props => props.current ? props.theme.colours.white : 'transparent'};
  transition: background-color 0.25s;
  margin: 0 0.2rem;
  padding: 0;
  border-radius: 50%;
  @media( ${props => props.theme.breakpoints.md} ) { 
    width: 0.8rem;
    height: 0.8rem;
  }
  &:hover {
    border-color: ${props => props.current ? props.theme.colours.white : props.theme.colours.black};
    background-color: ${props => props.current ? props.theme.colours.white : 'transparent'};
  }
`;

function GalleryIndicators({current, images, clickHandler, hideDesktop, hideMobile}) {
    return (
      <Holder hideDesktop={hideDesktop} hideMobile={hideMobile}>
        {images.map((image, i) => <Button key={i} type="button" current={current === i} name={image.image.alt} onClick={() => clickHandler(i)}/> )}
      </Holder>
    )
}

GalleryIndicators.propTypes = {
  current: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
  clickHandler: PropTypes.func.isRequired,
  hideDesktop: PropTypes.bool,
  hideMobile: PropTypes.bool,
};
GalleryIndicators.defaultProps = {
  hideDesktop: false,
  hideMobile: false,
};

export default GalleryIndicators;
