export const theme = {
  colours: {
    black: '#000000',
    white: '#ffffff',
    green: '#329952',
    grey: '#F1F1F0',
    gold: '#B89666',
    // Accessibility and forms
    focus: '#000000',
    error: '#000000',
  },
  typography: {
    min: 16,
    mid: 18,
    max: 24,
    minScreen: 400,
    midScreen: 1024,
    maxScreen: 1600,
    scale: {
      min: 1.205,
      mid: 1.205,
      max: 1.205,
    }
  },
  // https://www.smashingmagazine.com/2016/05/fluid-typography/
  fluidType: ( exp ) => {
    return `
      font-size: ${theme.typography.min * Math.pow( theme.typography.scale.min, exp )}px;
      @media screen and (min-width: ${theme.typography.minScreen}px ) {
        font-size: calc( ${theme.typography.min * Math.pow( theme.typography.scale.min, exp )}px + (${theme.typography.mid * Math.pow( theme.typography.scale.mid, exp )} - ${theme.typography.min * Math.pow( theme.typography.scale.min, exp )})*(100vw - ${theme.typography.minScreen}px)/(${theme.typography.midScreen} - ${theme.typography.minScreen}) );
      }
      @media screen and (min-width: ${theme.typography.midScreen}px ) {
        font-size: calc( ${theme.typography.mid * Math.pow( theme.typography.scale.mid, exp )}px + (${theme.typography.max * Math.pow( theme.typography.scale.max, exp )} - ${theme.typography.mid * Math.pow( theme.typography.scale.mid, exp )})*(100vw - ${theme.typography.midScreen}px)/(${theme.typography.maxScreen} - ${theme.typography.midScreen}) );
      }
      @media screen and (min-width: ${theme.typography.maxScreen}px ) {
        font-size: ${theme.typography.max * Math.pow( theme.typography.scale.max, exp )}px;
      }
      `
  },
  breakpoints: {
    sm: 'min-width: 576px',
    md: 'min-width: 768px',
    lg: 'min-width: 992px',
    xl: `min-width: 1200px`,
  },
};
