import React, { useEffect, useRef, useState } from 'react';
import _uniqueId from 'lodash/uniqueId';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin( ScrollTrigger );

function FadeUpAnimation( { children } ) {
  const [ id, setId ] = useState( null );
  const holder = useRef( null );
  const tl = useRef( null );

  useEffect( () => {
    setId( _uniqueId( 'fade-up-holder-' ) );
  }, [] );

  useEffect( () => {
    if ( !tl.current && id ) {

      tl.current = ScrollTrigger.matchMedia( {
        // desktop
        '(min-width: 576px)': function () {
          ScrollTrigger.saveStyles( `#${id}` );
          gsap.timeline( {
            scrollTrigger: {
              trigger: holder.current,
              start: 'top bottom',
              end: 'top 40%',
              scrub: 1,
            },
          } )
          .from( `#${id} `, {
            yPercent: 50,
            autoAlpha: 0,
            duration: 1,
            ease: 'power2.out',
          } );
        }
      } );

    }

  }, [ id ] );

  return (
    <div ref={holder}>
      <div id={id}>{children}</div>
    </div>
  )
}

export default FadeUpAnimation;
