import {createGlobalStyle} from 'styled-components';

import TeodorWoff2 from './../../assets/fonts/Teodor-Light/Teodor-Light.woff2';
import TeodorWoff from './../../assets/fonts/Teodor-Light/Teodor-Light.woff';

import FKGroteskNeueWoff2 from './../../assets/fonts/FK-Grotesk-Neue/FKGroteskNeue-Regular.woff2';
import FKGroteskNeueWoff from './../../assets/fonts/FK-Grotesk-Neue/FKGroteskNeue-Regular.woff';

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "Teodor";
    font-weight: normal;
    src: url('${TeodorWoff2}') format("woff2"),
    url('${TeodorWoff}') format("woff");
    font-display: fallback;
  }

  @font-face {
    font-family: "FKGroteskNeue";
    font-weight: normal;
    src: url('${FKGroteskNeueWoff2}') format("woff2"),
    url('${FKGroteskNeueWoff}') format("woff");
    font-display: fallback;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${props => props.theme.fluidType(0)};

    * { box-sizing: border-box; }
  }

  body {
    margin: 0;
    font-family: "FKGroteskNeue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: ${props => props.theme.colours.black};
    background-color: ${props => props.theme.colours.white};
  }

  .tredor, .serif {
    font-family: "Teodor", serif;
  }

  .sans {
    font-family: "FKGroteskNeue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: "Teodor", serif;
    line-height: 1.05;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: normal;

    a { text-decoration: none; }

    a:hover { text-decoration: none; }
  }

  p, .p,
  ol, ul, li,
  code, kbd, pre, samp {
    font-family: "FKGroteskNeue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    ${props => props.theme.fluidType(0)};
    line-height: 1.4;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    letter-spacing: -0.02em;
  }

  h1, .h1, .apply-h1 h2, .apply-h1 h3 {
    ${props => props.theme.fluidType(4.5)};
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
    @media ( ${props => props.theme.breakpoints.sm} ) {
      ${props => props.theme.fluidType(6)};
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    &.sans,
    strong,
    span.sans {
      font-weight: normal;
      font-family: "FKGroteskNeue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      ${props => props.theme.fluidType(4)};
      @media ( ${props => props.theme.breakpoints.sm} ) {
        ${props => props.theme.fluidType(5.5)};
      }
    }
  }

  h1.large-mobile, .h1.large-mobile,
  .large-mobile h1, .large-mobile .h1 {
    ${props => props.theme.fluidType(6.5)};
    @media ( ${props => props.theme.breakpoints.sm} ) {
      ${props => props.theme.fluidType(6)};
    }

    &.sans,
    strong,
    span.sans {
      ${props => props.theme.fluidType(6)};
      @media ( ${props => props.theme.breakpoints.sm} ) {
        ${props => props.theme.fluidType(5.5)};
      }
    }
  }

  h2, .h2 {
    ${props => props.theme.fluidType(4.5)};
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;

    &.sans,
    strong,
    span.sans {
      font-weight: normal;
      font-family: "FKGroteskNeue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      ${props => props.theme.fluidType(4)};
    }
  }
  h2.small-sans-uppercase,
  .h2.small-sans-uppercase,
  .small-sans-uppercase,
  .small-sans-uppercase h2 {
    font-family: "FKGroteskNeue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-transform: uppercase;
    letter-spacing: 0.07em;
    line-height: 1.4;
    margin: 0;
    ${props => props.theme.fluidType(0)};
    @media ( ${props => props.theme.breakpoints.sm} ) {
      ${props => props.theme.fluidType(-1)};
    }
  }

  h3, .h3 { ${props => props.theme.fluidType(3)}; }

  h4, .h4 { ${props => props.theme.fluidType(2)}; }

  h5, .h5 { ${props => props.theme.fluidType(1)}; }

  h6, .h6 { ${props => props.theme.fluidType(-1)}; }

  li {
    margin-top: 0;
    margin-bottom: 0;
  }

  small, p.small { ${props => props.theme.fluidType(-3)}; }

  code, kbd, pre, samp {
    font-family: monospace;
    white-space: normal;
  }

  ul {
    padding-left: 1rem;
    list-style-type: disc;
  }

  ol {
    padding-left: 1rem;
    list-style-type: decimal;
  }

  video {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }

  em, i { font-style: italic; }

  strong, b { font-weight: bold; }

  blockquote {
    font-weight: bold;
    padding-left: 1rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  p a {
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.colours.green};
    }
    &.white-hover:hover {
      color: ${props => props.theme.colours.white};
    }
  }
  .block-img {
    img {
      width: 100%;
      height: auto;
      display: block;
      margin: 3rem 0;
    }
  }
  
  .embed {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    margin: 3rem 0;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  sup, sub {
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }

  sub { top: 0.4em; }

  label {
    ${props => props.theme.fluidType(-1)};
    line-height: 1.2;
    font-weight: normal;
    display: none;
  }

  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
    color: inherit;
    display: block;
    font-family: inherit;
    ${props => props.theme.fluidType(0)};
    padding: 0.3rem 0;
    width: 100%;
    margin: 0.2rem 0 0 0;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    line-height: 2;
    background-color: transparent;
    -webkit-appearance: none;
    @media ( ${props => props.theme.breakpoints.md} ) {
      line-height: 1.6;
    }

    &:focus {
      outline: none;
      border-color: ${props => props.theme.colours.focus};
    }
  }

  textarea {
    border: 1px solid;
    resize: none;
    padding: 0.5rem;
    margin-top: 2.5rem;
  }

  ::placeholder {
    color: inherit;
    opacity: 1;
  }

  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
    font-family: inherit;
  }

  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
    display: inline-block;
    cursor: pointer;

    padding: 0 4rem;

    font-family: inherit;
    ${props => props.theme.fluidType(0)};
    line-height: 2.5;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    border: none;
    border-radius: 0;

    color: ${props => props.theme.colours.white};
    background-color: ${props => props.theme.colours.green};
    transition: all 0.25s;
    -webkit-appearance: none;

    @media ( ${props => props.theme.breakpoints.md} ) {
      ${props => props.theme.fluidType(-1)};
    }

    &.white {
      color: ${props => props.theme.colours.gold};
      background-color: ${props => props.theme.colours.white};
    }

    &:hover {
      border-color: ${props => props.theme.colours.black};
      background-color: ${props => props.theme.colours.black};
    }

    &.link {
      border: none;
      padding: 0;
      background-color: transparent;
      color: inherit;
      white-space: normal;
    }

    &.icon {
      border: none;
      padding: 0;
      background-color: transparent;
      line-height: 1;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.3;
      cursor: auto;
    }
  }

  .errorMsg {
    color: ${props => props.theme.colours.error};
    margin: 0;
  }
`;

export default GlobalStyle;
