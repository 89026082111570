import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';

const Holder = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 65%;
  position: relative;
  @media( ${props => props.theme.breakpoints.xl} ) { 
    padding-bottom: 70vh;
  }
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

function PageHeroImage({image}) {
    return (
      <Holder>
        <Inner>
          <GatsbyImage
            alt={image.alt}
            style={{
              width: '100%',
              height: '100%'
            }}
            fluid={image.fluid}/>
        </Inner>
      </Holder>
    )
}

PageHeroImage.propTypes = {
  image: PropTypes.object.isRequired
};

export default PageHeroImage;
