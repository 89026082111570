import React from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import {useStaticQuery, graphql} from 'gatsby';
import Logo from '../atoms/Logo';
import PrismicRichText from "../atoms/PrismicRichText";
import {useMergePrismicPreviewData} from "gatsby-plugin-prismic-previews";

const Holder = styled.footer`
  background-color: ${props => props.theme.colours.green};
  padding-top: 6rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding-top: 4rem;
  }
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 4rem;
  }

  p {
    ${props => props.theme.fluidType(-1)};
  }

  a {
    text-decoration: underline;

    &:hover {
      color: ${props => props.theme.colours.white};
    }
  }
`;

const Text = styled.div`
  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }

  &.credit {
    margin-bottom: 2rem;
    grid-column: 2/3;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      grid-column: 4/5;
    }
  }

  &.sub-menu {
    grid-column: 1/2;
  }

  h2 {
    ${props => props.theme.fluidType(4)};
    margin-bottom: 0;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(1)};
      margin-bottom: 1rem;
    }
  }
`;

const LogoHolder = styled.div`
  display: none;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: block;
  }

  svg path { fill: ${props => props.theme.colours.white}; }

  > :first-child { margin-top: 0; }

  > :last-child { margin-bottom: 0; }
`;

function Footer() {

  const staticData = useStaticQuery(graphql`
      query FooterQuery {
          prismicFooter {
              _previewable
              data {
                  text_area_1 {
                      raw
                  }
                  text_area_2 {
                      raw
                  }
                  text_area_3 {
                      raw
                  }
                  text_area_4 {
                      raw
                  }
                  credit {
                      raw
                  }
              }
          }
      }
  `)

  const {data} = useMergePrismicPreviewData(staticData)

  return (
    <Holder>
      <Container>
        <Inner>
          <Text>
            <PrismicRichText render={data.prismicFooter.data.text_area_1.raw} />
          </Text>
          <Text>
            <PrismicRichText render={data.prismicFooter.data.text_area_2.raw} />
          </Text>
          <Text>
            <PrismicRichText render={data.prismicFooter.data.text_area_3.raw} />
          </Text>
          <LogoHolder>
            <Logo />
          </LogoHolder>
          <Text className="sub-menu">
            <PrismicRichText render={data.prismicFooter.data.text_area_4.raw} />
          </Text>
          <Text className="credit">
            <PrismicRichText render={data.prismicFooter.data.credit.raw} />
          </Text>
        </Inner>
      </Container>
    </Holder>
  )
}

export default Footer;
