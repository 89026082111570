import React from 'react';
import Seo from '../components/molecules/Seo';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PageHeroImage from '../components/organisms/PageHeroImage';
import { RichText } from 'prismic-reactjs';
import TwoColumn from '../components/organisms/TwoColumn';
import ContactForm from '../components/molecules/ContactForm';
import ReferralsHolder from "../components/organisms/ReferralsHolder";
import PageTitle from "../components/atoms/PageTitle";
import Breadcrumbs from "../components/molecules/Breadcrumbs";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";


const Holder = styled.article`
  background-color: ${props => props.theme.colours.grey};
  @media( ${props => props.theme.breakpoints.md} ) { 
    padding: 3rem 0;
  }
  a {
    text-decoration: underline;
  }
  .two-column-holder {
    align-items: start;
    padding: 3rem 0;
  }
  input[type="submit"] {
    margin-top: 2rem;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    border-color: ${props => props.theme.colours.green};
    &:focus {
      border-color: ${props => props.theme.colours.black};
    }
  }
`;


function ContactPage( { data } ) {
  const {
    hero,
    intro,
    page_title,
    address,
    strapline,
    phone,
    referrals_text
  } = data.prismicContact.data;
  return (
    <>
      <Seo title={page_title ? page_title.text : 'Contact Us'}/>
      <PageHeroImage image={hero}/>
      <Holder>
        <PageTitle>{page_title ? page_title.text : 'Contact Us'}</PageTitle>
        <TwoColumn>
          <div>
            <RichText render={strapline.raw}/>
          </div>
          <div>
            <RichText render={intro.raw}/>
          </div>
        </TwoColumn>
        <TwoColumn>
          <div>
            <p>
              <a href={`tel:${phone.text.replace(' ', '')}`} target="_blank" rel="noopener noreferrer">{phone.text}</a>
            </p>
            <RichText render={address.raw}/>
          </div>
          <div>
            <ContactForm/>
          </div>
        </TwoColumn>
      </Holder>
      <ReferralsHolder text={referrals_text}/>
      <Breadcrumbs bgColor="gold" color="white" linkHover="black">
        {page_title ? page_title.text : 'Contact Us'}
      </Breadcrumbs>
    </>
  )
};

export default withPrismicPreview(ContactPage);

export const pageQuery = graphql`
    query {
        prismicContact {
            _previewable
            data {
                page_title {
                    text
                }
                hero {
                    alt
                    fluid(maxWidth: 4000) {
                        ...GatsbyPrismicImageFluid_noBase64
                    }
                }
                intro {
                    raw
                }
                address {
                    raw
                }
                strapline {
                    raw
                }
                phone {
                  text
                }
                referrals_text {
                    raw
                }
            }
        }
    }
`;
