import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../atoms/Container';
import GatsbyImage from 'gatsby-image';
import { RichText } from "prismic-reactjs";

const Holder = styled.article`
  background-color: ${props => props.theme.colours.black};
  color: ${props => props.theme.colours.white};
  padding: 2rem 0 3rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    padding-bottom: 4rem;
  }
  h2 {
    margin-bottom: 2rem;
  }
`;

const Inner = styled.ul`
  list-style: none;
  padding-left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  }
`;

const PersonHolder = styled.div`
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  .role {
    margin-bottom: 1.5rem;
  }
  .h2 {
    margin-bottom: 0;
  }
`;

function LeadershipTeam( { team, title } ) {
  return (
    <Holder>
      <Container>
        <h2>{title}</h2>
        <Inner>
          {team && team.map( person =>
            <PersonHolder key={person.name.text}>
              <GatsbyImage alt={person.portrait.alt} fluid={person.portrait.fluid}/>
              <h3 className="h2">{person.name.text}</h3>
              <p className="role">{person.role.text}</p>
              <RichText render={person.bio.raw}/>
            </PersonHolder>
          )}
        </Inner>
      </Container>
    </Holder>
  )
}

LeadershipTeam.propTypes = {
  team: PropTypes.array.isRequired,
  title: PropTypes.string,
};

LeadershipTeam.defaultProps = {
  title: 'Leadership team',
};

export default LeadershipTeam;
