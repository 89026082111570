import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import {Link, useStaticQuery, graphql} from 'gatsby';
import OpenIcon from '../../assets/open.inline.svg';
import {convertToSlug} from "../../utils/helpers";
import {useMergePrismicPreviewData} from "gatsby-plugin-prismic-previews";

const listDelay = 250;
const listStagger = 100;

const Holder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.colours.green};
  z-index: 11;
  @media ( ${props => props.theme.breakpoints.md} ) {
    left: auto;
    width: 50%;
    min-width: 510px;
  }

  &.navigation-appear,
  &.navigation-enter {
    transform: translateX(100%);

    .list {
      li {
        opacity: 0;
        transform: translateY(25%);
      }
    }
  }

  &.navigation-appear-active,
  &.navigation-enter-active {
    transform: translateX(0);
    transition: transform 250ms;

    .list {
      li {
        opacity: 1;
        transform: none;
        transition: all 150ms ${listDelay}ms ease-out;

        &:nth-child(2) { transition-delay: ${listDelay + listStagger}ms; }

        &:nth-child(3) { transition-delay: ${listDelay + listStagger * 2}ms; }

        &:nth-child(4) { transition-delay: ${listDelay + listStagger * 3}ms; }

        &:nth-child(5) { transition-delay: ${listDelay + listStagger * 4}ms; }

        &:nth-child(6) { transition-delay: ${listDelay + listStagger * 5}ms; }

        &:nth-child(7) { transition-delay: ${listDelay + listStagger * 6}ms; }

        &:nth-child(8) { transition-delay: ${listDelay + listStagger * 7}ms; }
      }
    }
  }

  &.navigation-exit {
    transform: translateX(0);

    .list {
      opacity: 0;
    }
  }

  &.navigation-exit-active {
    transform: translateX(100%);
    transition: transform 250ms;
  }
`;

const Inner = styled.div`
  padding: 4.8rem 1rem 4rem 1rem;
  width: 100%;
  max-width: ${props => props.theme.typography.maxScreen}px;
  margin: 0 auto;
  height: 100%;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: 4.8rem 4rem 4rem 4rem;
  }
`;


const ListHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media ( ${props => props.theme.breakpoints.md} ) {
    justify-content: center;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }
`;

const PrimaryList = styled.ul`
  margin-top: 4rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-top: 0;
  }

  li {
    ${props => props.theme.fluidType(5.5)};
    font-family: "Teodor", serif;
    margin: 0;
    line-height: 1.1;
    @media ( ${props => props.theme.breakpoints.md} ) {
      ${props => props.theme.fluidType(6.5)};
    }

    svg {
      margin-left: 1rem;
      width: 1.8rem;
      height: auto;
    }

    a:hover {
      transition: color 0.15s ease-in-out;
      color: ${props => props.theme.colours.white};

      svg {
        line, path {
          transition: stroke 0.15s ease-in-out;
          stroke: ${props => props.theme.colours.white};
        }
      }
    }
  }
`;

const SecondaryList = styled.ul`
  @media ( ${props => props.theme.breakpoints.md} ) {
    display: none;
  }

  li {
    a { text-decoration: underline; }
  }
`;

function Navigation({show, clickHandler}) {

  const staticData = useStaticQuery(graphql`
      query NavQuery {
          prismicGlobalSettings {
              _previewable
              data {
                  navigation {
                      slug {
                          text
                      }
                      title {
                          text
                      }
                  }
                  secondary_mobile_navigation {
                      title {
                          text
                      }
                      url {
                          url
                      }
                  }
              }
          }
      }
  `)

  const {data} = useMergePrismicPreviewData(staticData)

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear
      in={show}
      timeout={1450}
      classNames="navigation"
    >
      <Holder>
        <Inner>
          <ListHolder>

            <PrimaryList className="tredor list" onClick={() => clickHandler(false)}>
              {data.prismicGlobalSettings.data.navigation.length > 0 && data.prismicGlobalSettings.data.navigation.map(item =>
                <li key={item.slug.text}><Link to={item.slug.text}>{item.title.text}<OpenIcon /></Link></li>
              )}
            </PrimaryList>
            <SecondaryList className="list" onClick={() => clickHandler(false)}>
              {data.prismicGlobalSettings.data.secondary_mobile_navigation.length > 0 && data.prismicGlobalSettings.data.secondary_mobile_navigation.map(item =>
                <li key={convertToSlug(item.title.text)}><a href={item.url.url} target="_blank"
                                                            rel="noopener noreferrer">{item.title.text}</a></li>
              )}
            </SecondaryList>
          </ListHolder>
        </Inner>
      </Holder>
    </CSSTransition>
  )
}

Navigation.propTypes = {
  show: PropTypes.bool.isRequired,
  clickHandler: PropTypes.func.isRequired
};

Navigation.defaultProps = {
  show: false
};

export default Navigation;
