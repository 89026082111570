import React from 'react';
import Seo from '../components/molecules/Seo';
import {graphql} from 'gatsby';
import styled from 'styled-components';
import PageHeroImage from '../components/organisms/PageHeroImage';
import TwoColumn from '../components/organisms/TwoColumn';
import Container from '../components/atoms/Container';
import AboutGridImages from '../components/organisms/AboutGridImages';
import LeadershipTeam from '../components/organisms/LeadershipTeam';
import PrismicRichText from "../components/atoms/PrismicRichText";
import PageTitle from "../components/atoms/PageTitle";
import Breadcrumbs from "../components/molecules/Breadcrumbs";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";


const Holder = styled.article`
  background-color: ${props => props.theme.colours.grey};
  padding-bottom: 4rem;
  @media( ${props => props.theme.breakpoints.md} ) { 
    padding-bottom: 2rem;
  }
`;

const Intro = styled.div`
  .two-column-holder {
    align-items: start;
  }
`;

const Text = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: ${props => props.primary ? '0 0 2rem 0' : '2rem 0'};
  border-top: ${props => props.primary ? '1px solid' : 'none'};
  padding-top: ${props => props.primary ? '2rem' : '0'};
  border-color: ${props => props.theme.colours.green};
  grid-gap: 1rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    margin: ${props => props.primary ? '0 0 3rem 0' : '3rem 0'};
    padding-top: ${props => props.primary ? '3rem' : '0'};
    > div {
      grid-column: ${props => props.primary ? '1/2' : '2/3'};
    }
  }
`;

function AboutUs( { data } ) {
  const {
    leadership_team,
    leadership_title,
    hero,
    page_title,
    strapline,
    intro,
    grid_images,
    primary_text,
    secondary_grid_images,
    secondary_text
  } = data.prismicAboutUs.data;
  return (
    <>
      <Seo title={page_title ? page_title.text : 'About Us'}/>
      <PageHeroImage image={hero}/>
      <Holder>
        <PageTitle>{page_title ? page_title.text : 'About Us'}</PageTitle>
        <Intro>
          <TwoColumn>
            <div className="large-mobile">
              <PrismicRichText render={strapline.raw}/>
            </div>
            <div>
              <PrismicRichText render={intro.raw}/>
            </div>
          </TwoColumn>
        </Intro>
        <Container>
          <Text primary>
            <div>
              <PrismicRichText render={primary_text.raw}/>
            </div>
          </Text>
        </Container>
        <AboutGridImages images={grid_images} primary/>
        <Container>
          <Text>
            <div>
              <PrismicRichText render={secondary_text.raw}/>
            </div>
          </Text>
        </Container>
        <AboutGridImages images={secondary_grid_images}/>
      </Holder>
      <LeadershipTeam title={leadership_title.text} team={leadership_team}/>
      <Breadcrumbs color="white" bgColor="black">
        {page_title ? page_title.text : 'About Us'}
      </Breadcrumbs>
    </>
  )
};

export default withPrismicPreview(AboutUs);

export const pageQuery = graphql`
    query {
        prismicAboutUs {
            _previewable
            data {
                page_title {
                    text
                }
                strapline {
                    raw
                }
                leadership_title {
                  text
                }
                leadership_team {
                    bio {
                        raw
                    }
                    name {
                        text
                    }
                    role {
                        text
                    }
                    portrait {
                        alt
                        fluid(maxWidth: 4000) {
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                }
                intro {
                    raw
                }
                hero {
                    alt
                    fluid(maxWidth: 4000) {
                        ...GatsbyPrismicImageFluid_noBase64
                    }
                }
                grid_images {
                    image {
                        alt
                        fluid(maxWidth: 4000) {
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                }
                primary_text {
                    raw
                }
                secondary_grid_images {
                    image {
                        alt
                        fluid(maxWidth: 4000) {
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                }
                secondary_text {
                    raw
                }
            }
        }
    }
`;
