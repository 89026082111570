import React from 'react';
import {graphql} from 'gatsby';
import Seo from '../components/molecules/Seo';
import styled from 'styled-components';
import PageHeroImage from '../components/organisms/PageHeroImage';
import TwoColumn from '../components/organisms/TwoColumn';
import PrismicRichText from "../components/atoms/PrismicRichText";
import PageTitle from "../components/atoms/PageTitle";
import Breadcrumbs from "../components/molecules/Breadcrumbs";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";


const Holder = styled.article`
  background-color: ${props => props.theme.colours.grey};

  .two-column-holder {
    align-items: start;

    h1 {
      margin-bottom: 1rem;
    }
  }
`;

function News({data}) {
  const page = data.prismicPage.data;
  return (
    <>
      <Seo title={page.page_title.text} />
      <PageHeroImage image={page.hero} />
      <Holder>
        <PageTitle>{page.page_title.text}</PageTitle>
        <TwoColumn>
          <div>
            <PrismicRichText render={page.strapline.raw} />
          </div>
          <div>
            <PrismicRichText render={page.text.raw} />
          </div>
        </TwoColumn>
        <Breadcrumbs>
          {page.page_title.text}
        </Breadcrumbs>
      </Holder>
    </>
  )
}

export default withPrismicPreview(News);

export const pageQuery = graphql`
    query($id: String!) {
        prismicPage(id: {eq: $id}) {
            _previewable
            data {
                hero {
                    alt
                    fluid(maxWidth: 4000) {
                        ...GatsbyPrismicImageFluid_noBase64
                    }
                }
                page_title {
                    text
                }
                strapline {
                    raw
                }
                text {
                  text
                    raw
                }
            }
        }
    }
`;