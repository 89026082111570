import React from 'react';
import styled from 'styled-components';
import Container from "../atoms/Container";
import PropTypes from 'prop-types';
import {Link} from "gatsby";

const Holder = styled.div`
  padding: 1rem 0;
  color: ${props => props.color ? props.theme.colours[props.color] : 'inherit'};
  background-color: ${props => props.bgColor ? props.theme.colours[props.bgColor] : 'transparent'};
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 2rem 0;
  }
  p {
    margin: 0;
  }
  a {
    &:hover { color: ${props => props.linkHover ? props.theme.colours[props.linkHover] : props.theme.colours.green}; }
  }
`;

function Breadcrumbs({children, bgColor, color, linkHover}) {
  return (
    <Holder color={color} bgColor={bgColor} linkHover={linkHover}>
      <Container>
        <p><Link to="/">Home</Link> / {children}</p>
      </Container>
    </Holder>
  )
}

Breadcrumbs.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  linkHover: PropTypes.string,
};

export default Breadcrumbs;