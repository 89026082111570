import React from 'react';
import {graphql, Link} from 'gatsby';
import Seo from '../components/molecules/Seo';
import styled from 'styled-components';
import PageHeroImage from '../components/organisms/PageHeroImage';
import Container from '../components/atoms/Container';
import {RichText} from 'prismic-reactjs';
import NewsGridImages from '../components/organisms/NewsGridImages';
import Gallery from '../components/organisms/Gallery';
import MoreNews from '../components/organisms/MoreNews';
import PageTitle from "../components/atoms/PageTitle";
import Breadcrumbs from "../components/molecules/Breadcrumbs";
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'


const Holder = styled.article`
  background-color: ${props => props.theme.colours.grey};
`;

const IntroText = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 3rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 2fr 1fr;
    padding-bottom: 6rem;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 6fr 4fr;
  }

  h1.p { margin: 0 0 1rem 0; }

  h2 { margin-top: 0; }
`;

const Text = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 3rem 0;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    margin: 6rem 0;
  }
`;

function News({data}) {
  const post = data.prismicNewsPost.data;
  const {page_title} = data.prismicNews.data;
  return (
    <>
      <Seo title={post.title.text} />
      <PageHeroImage image={post.hero} />
      <Holder>
        <PageTitle>{page_title ? page_title.text : 'News'}</PageTitle>
        <Container>
          <IntroText>
            <div>
              <h1 className="p">{post.title.text}</h1>
              <RichText render={post.intro.raw} />
            </div>
          </IntroText>
        </Container>
        {post.grid_images.length > 0 && <NewsGridImages images={post.grid_images} />}
        {post.text.text && <Container>
          <Text>
            <div>
              <RichText render={post.text.raw} />
            </div>
          </Text>
        </Container>}
        <Container>
          <Gallery gallery={post.image_gallery} />
        </Container>
          <Breadcrumbs>
              <Link to="/news">News</Link> / {post.title.text}
          </Breadcrumbs>
      </Holder>
      {data.allPrismicNewsPost.nodes.length > 0 && <MoreNews posts={data.allPrismicNewsPost.nodes} />}
    </>
  )
}

export default withPrismicPreview( News );

export const newsPageQuery = graphql`
    query($id: String!) {
        prismicNewsPost(id: {eq: $id}) {
            _previewable
            uid
            data {
                hero {
                    alt
                    fluid(maxWidth: 4000) {
                        ...GatsbyPrismicImageFluid_noBase64
                    }
                }
                preview_image {
                    alt
                    fluid(maxWidth: 1000) {
                        ...GatsbyPrismicImageFluid_noBase64
                    }
                }
                title {
                    raw
                    text
                }
                excerpt {
                    raw
                }
                text {
                    raw
                    text
                }
                intro {
                    raw
                }
                image_gallery {
                    image {
                        alt
                        fluid(maxWidth: 4000) {
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                }
                grid_images {
                    image {
                        alt
                        fluid(maxWidth: 1000) {
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                }

            }
        }
        allPrismicNewsPost(
            limit: 4
            sort: {fields: first_publication_date, order: DESC}
            filter: {id: {ne: $id}}
        ) {
            nodes {
                uid
                data {
                    preview_image {
                        alt
                        fluid(maxWidth: 1000) {
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                    title {
                        raw
                        text
                    }
                    excerpt {
                        raw
                    }
                }
            }
        }
        prismicNews {
            data {
                page_title {
                    text
                }
            }
        }
    }
`;
