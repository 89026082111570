import React from 'react';
import {graphql, Link} from 'gatsby';
import Seo from '../components/molecules/Seo';
import styled from 'styled-components';
import PageHeroImage from '../components/organisms/PageHeroImage';
import TwoColumn from '../components/organisms/TwoColumn';
import ProjectGridImages from '../components/organisms/ProjectGridImages';
import Container from '../components/atoms/Container';
import Gallery from '../components/organisms/Gallery';
import ProjectNumbers from '../components/organisms/ProjectNumbers';
import PrismicRichText from "../components/atoms/PrismicRichText";
import PageTitle from "../components/atoms/PageTitle";
import ProjectPageSlider from "../components/organisms/ProjectPageSlider";
import Breadcrumbs from "../components/molecules/Breadcrumbs";
import GatsbyImage from "gatsby-image";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";


const Holder = styled.article`
  background-color: ${props => props.theme.colours.grey};

  .two-column-holder {
    align-items: start;

    h1 {
      margin-bottom: 1rem;
    }
  }
`;

const Text = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 3rem 0;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    margin: 6rem 0;
  }
`;

const FloorplanHolder = styled.div`
  margin-bottom: 3rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-bottom: 6rem;
  }
`;

const GalleryHolder = styled.div`
  margin-bottom: 3rem;
`;

function Project({data}) {
  const post = data.prismicProject.data;
  const {page_title} = data.prismicOurProjects.data;
  return (
    <>
      <Seo title={post.title.text} />
      <PageHeroImage image={post.hero} />
      <Holder>
        <PageTitle>{page_title ? page_title.text : 'Our Projects'}:<br />{post.title.text}</PageTitle>
        <TwoColumn>
          <div>
            <PrismicRichText render={post.strapline.raw} />
            <PrismicRichText render={post.address.raw} />
          </div>
          <div>
            <PrismicRichText render={post.introduction.raw} />
          </div>
        </TwoColumn>
        <ProjectNumbers title={post.project_numbers_title} paragraphs={post.project_numbers} />
        {post.grid_images.length > 0 && <ProjectGridImages images={post.grid_images} />}
        {post.text.text && <Container>
          <Text>
            <div>
              <PrismicRichText render={post.text.raw} />
            </div>
          </Text>
        </Container>}
        {post.floorplan && <FloorplanHolder>
          <Container>
            <GatsbyImage
              alt={post.floorplan.alt || 'floorplan'}
              fluid={post.floorplan.fluid} />
          </Container>
        </FloorplanHolder>}
        {post.slider[0] && <ProjectPageSlider slides={post.slider} heading={post.slider_title.text} />}
        <GalleryHolder>
          <Container>
            <Gallery gallery={post.gallery} />
          </Container>
        </GalleryHolder>
        <Breadcrumbs>
          <Link to="/our-projects">Our Projects</Link> / {post.title.text}
        </Breadcrumbs>
      </Holder>
    </>
  )
}

export default withPrismicPreview(Project);

export const projectPageQuery = graphql`
    query($id: String!) {
        prismicProject(id: {eq: $id}) {
            _previewable
            data {
                hero {
                    alt
                    fluid(maxWidth: 4000) {
                        ...GatsbyPrismicImageFluid_noBase64
                    }
                }
                title {
                    text
                }
                strapline {
                    raw
                }
                address {
                    raw
                }
                introduction {
                    raw
                }
                text {
                  text
                    raw
                }
                grid_images {
                    image {
                        alt
                        fluid(maxWidth: 1000) {
                            aspectRatio
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                }
               floorplan {
                  alt
                  fluid(maxWidth: 3000) {
                    ...GatsbyPrismicImageFluid_noBase64
                  }
                }
                gallery {
                    image {
                        alt
                        fluid(maxWidth: 4000) {
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                }
                slider {
                    slide_image {
                        alt
                        fluid(maxWidth: 1000) {
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                    slide_text {
                        raw
                    }
                    slide_title {
                        text
                    }
                }
                slider_title {
                    text
                }
                project_numbers_title {
                  raw
                }
                project_numbers {
                    numbers_paragraph {
                        raw
                    }
                }
            }
        }
        prismicOurProjects {
            _previewable
            data {
                page_title {
                    text
                }
            }
        }
    }
`;