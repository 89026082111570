import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import { CSSTransition } from 'react-transition-group'

const Holder = styled.div`
  width: 100%;
  height: 0;
  position: relative;
  z-index: 1;
  padding-bottom: ${props => props.mobileHeight}%;
  @media( ${props => props.theme.breakpoints.sm} ) { 
    padding-bottom: 60%;
  }
`;

const ImageHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &.slide-appear,
  &.slide-enter {
    opacity: 0;
  }
  &.slide-appear-active,
  &.slide-appear-done,
  &.slide-enter-active,
  &.slide-enter-done {
    opacity: 1;
    transition: opacity 500ms;
  }
  &.slide-exit {
    opacity: 1;
  }
  &.slide-exit-active {
    opacity: 0;
    transition: opacity 500ms;
  }
`;

function GalleryImages( { images, current, mobileHeight } ) {
  return (
    <Holder mobileHeight={mobileHeight}>
      {images && images.map( ( slide, i ) =>
        <CSSTransition
          mountOnEnter
          unmountOnExit
          appear
          in={i === current}
          timeout={500}
          classNames="slide"
          key={i}
        >
          <ImageHolder>
            <GatsbyImage
              alt={slide.image.alt}
              style={{
                width: '100%',
                height: '100%'
              }}
              fluid={slide.image.fluid}/>
          </ImageHolder>
        </CSSTransition>
      )}
    </Holder>
  )
}

GalleryImages.propTypes = {
  current: PropTypes.number.isRequired,
  mobileHeight: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
};

GalleryImages.defaultProps = {
  mobileHeight: 60
};

export default GalleryImages;
