import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';
import PrismicRichText from "../atoms/PrismicRichText";
import GatsbyImage from "gatsby-image";

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  margin-bottom: 2rem;
  grid-auto-flow: dense;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 3rem;
    align-items: start;
  }
  
  > div {
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }

  &.slide-appear,
  &.slide-enter {
    position: absolute;
    opacity: 0;
  }

  &.slide-appear-active,
  &.slide-enter-active {
    position: absolute;
    opacity: 1;
    transition: opacity 250ms 250ms;
  }

  &.slide-appear-done,
  &.slide-enter-done {
    position: relative;
    opacity: 1;
  }

  &.slide-exit {
    opacity: 1;
  }

  &.slide-exit-active {
    opacity: 0;
    transition: opacity 250ms;
  }
`;

const TextHolder = styled.div`
  padding-top: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding-top: 8rem;
    grid-column: 1/2;
  }
`;

const ImageColumn = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: 2/3;
  }
`;

const ImageHolder = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

function Benefit({slide, show, i}) {
  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear
      in={show}
      timeout={500}
      classNames="slide"
    >
      <Holder>
        <ImageColumn>
          <ImageHolder>
            <div className="inner">
              {slide.slide_image.fluid && <GatsbyImage
                alt={slide.slide_image.alt}
                style={{
                  width: '100%',
                  height: '100%'
                }}
                fluid={slide.slide_image.fluid} />}
            </div>
          </ImageHolder>
        </ImageColumn>
        <TextHolder>
          <h3 className="h1">
            0{i + 1}<br /><span className="sans">{slide.slide_title.text}</span>
          </h3>
          <PrismicRichText render={slide.slide_text.raw} />
        </TextHolder>
      </Holder>
    </CSSTransition>
  )
}

Benefit.propTypes = {
  slide: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
};

export default Benefit;
