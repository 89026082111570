import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { RichText } from "prismic-reactjs";

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  padding: 4rem 0;
  @media( ${props => props.theme.breakpoints.md} ) { 
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 4rem 0;
    min-height: 40vh;
  }
  &.benefit-appear,
  &.benefit-enter {
    position: absolute;
    opacity: 0;
  }
  &.benefit-appear-active,
  &.benefit-enter-active {
    position: absolute;
    opacity: 1;
    transition: opacity 250ms 250ms;
  }
  &.benefit-appear-done,
  &.benefit-enter-done {
    position: relative;
    opacity: 1;
  }
  &.benefit-exit {
    opacity: 1;
  }
  &.benefit-exit-active {
    opacity: 0;
    transition: opacity 250ms;
  }
`;

function Benefit( { benefit, show, i } ) {
  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear
      in={show}
      timeout={500}
      classNames="benefit"
    >
      <Holder>
        <div>
          <h3 className="h1">
            0{i+1}<br/><span className="sans">{benefit.heading.text}</span>
          </h3>
        </div>
        <div>
          <RichText render={benefit.text.raw}/>
        </div>
      </Holder>
    </CSSTransition>
  )
}

Benefit.propTypes = {
  benefit: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
};

export default Benefit;
