import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BackArrow from '../../assets/backarrow.inline.svg';
import ForwardArrow from '../../assets/forwardarrow.inline.svg';

const Holder = styled.div`
  display: ${props => props.hideMobile ? 'none' : 'flex'};
  align-items: center;
  margin-top: 1rem;
  @media( ${props => props.theme.breakpoints.md} ) { 
    display: ${props => props.hideDesktop ? 'none' : 'flex'};
  }
`;

const Button = styled.button`
  svg {
    width: 1.8rem;
    height: auto;
    display: block;
  }
  &:first-child {
    margin-right: 1rem;
  }
`;

function GalleryArrows( { current, images, clickHandler, hideDesktop, hideMobile } ) {

  const findPrevious = (current, total) => {
    if( current === 0 ) {
      return total - 1;
    } else {
      return current - 1;
    }
  }

  const findNext = (current, total) => {
    if( current === total - 1 ) {
      return 0;
    } else {
      return current + 1;
    }
  }

  return (
    <Holder className="gallery-arrows" hideDesktop={hideDesktop} hideMobile={hideMobile}>
      <Button
        type="button"
        name="previous image"
        className="icon"
        onClick={() => clickHandler( findPrevious(current, images.length) )}>
        <BackArrow/>
      </Button>
      <Button
        type="button"
        name="next image"
        className="icon"
        onClick={() => clickHandler( findNext( current, images.length ) )}>
        <ForwardArrow/>
      </Button>
    </Holder>
  )
}

GalleryArrows.propTypes = {
  current: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
  clickHandler: PropTypes.func.isRequired,
  hideDesktop: PropTypes.bool,
  hideMobile: PropTypes.bool,
};
GalleryArrows.defaultProps = {
  hideDesktop: false,
  hideMobile: false,
};

export default GalleryArrows;
