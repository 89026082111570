export const animationBreaks = [ 0, 40, 95, 140, 200, 260, 280 ];

// Approach animation
const keyframes = [ 0, 44, 75, 126, 158, 217, 249, 300, 331, 390, 428, 463 ];
export const segments = {
  steps: [
    {
      // Step 1
      in: [ keyframes[ 10 ], keyframes[ 11 ] ],
      out: [ keyframes[ 1 ], keyframes[ 2 ] ],
    },
    {
      // Step 2
      in: [ keyframes[ 2 ], keyframes[ 3 ] ],
      out: [ keyframes[ 3 ], keyframes[ 4 ] ],
    },
    {
      // Step 3
      in: [ keyframes[ 4 ], keyframes[ 5 ] ],
      out: [ keyframes[ 5 ], keyframes[ 6 ] ],
    },
    {
      // Step 4
      in: [ keyframes[ 6 ], keyframes[ 7 ] ],
      out: [ keyframes[ 7 ], keyframes[ 8 ] ],
    },
    {
      // Step 5
      in: [ keyframes[ 8 ], keyframes[ 9 ] ],
      out: [ keyframes[ 9 ], keyframes[ 10 ] ],
    },
  ]
};
