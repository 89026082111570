import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import { PropTypes } from 'prop-types';
import GalleryImages from '../molecules/GalleryImages';
import GalleryIndicators from '../atoms/GalleryIndicators';
import {RichText} from "prismic-reactjs";

const Holder = styled.div`
  position: relative;
`;

const Text = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.colours.white};
  p {
    ${props => props.theme.fluidType( 4 )};
    line-height: 1.3;
    span {
      font-size: 1.2em;
    }
  }
`;

const Images = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 150%;
  @media( ${props => props.theme.breakpoints.sm} ) { 
    padding-bottom: 60%;
  }
`;

function HomeGallery( { gallery, text } ) {
  const [ current, setCurrent ] = useState( 0 );

  useEffect( () => {
    const timeOut = setTimeout( () => {
      if ( current === gallery.length - 1 ) {
        setCurrent( 0 )
      } else {
        setCurrent( current + 1 )
      }
    }, 6000 );
    return () => clearTimeout( timeOut );
  }, [ current, gallery.length ] );

  return (
    <Holder>
      <Images>
        <GalleryImages
          images={gallery}
          mobileHeight={150}
          current={current}/>
      </Images>
      <Text>
        <Container>
          <RichText render={text.raw}/>
        </Container>
      </Text>
      <GalleryIndicators clickHandler={( i ) => setCurrent( i )} images={gallery} current={current}/>
    </Holder>
  )
}

HomeGallery.propTypes = {
  gallery: PropTypes.array.isRequired,
  text: PropTypes.object.isRequired,
};

export default HomeGallery;
