import React, {useRef} from 'react';
import styled from 'styled-components';
import animationData from '../../assets/CoDevelop-Header-Animation-V2';
import Lottie from 'react-lottie-player';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const Holder = styled.div`
  position: sticky;
  top: 4.8rem;
  left: 0;
  right: 0;
  z-index: 0;
  color: ${props => props.theme.colours.black};
  background-color: ${props => props.theme.colours.grey};
  
  overflow: hidden;
  transition: height 1.5s cubic-bezier(0.33, 1, 0.68, 1);

  height: 22rem;
  @media ( ${props => props.theme.breakpoints.sm} ) {
    height: 35rem;
  }
  @media ( ${props => props.theme.breakpoints.md} ) {
    height: calc(100vh - 4.8rem);
  }

  > div {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 22rem;
    @media ( ${props => props.theme.breakpoints.sm} ) {
      height: 35rem;
    }
    @media ( ${props => props.theme.breakpoints.md} ) {
      height: calc(100vh - 4.8rem);
      width: 50%;
      left: 25%;
    }

    svg {
      overflow: visible;
    }
  }
`;

function HomeHero() {
  const hero = useRef( null );

  const scrollDown = () => {
    if(hero.current) {
      hero.current.style.height = 0;
      setTimeout(() => {
        ScrollTrigger.refresh()
      }, 1500);
    }
  };

  return (
    <Holder ref={hero} onClick={() => scrollDown()}>
      <Lottie
        animationData={animationData}
        play={true}
        loop={false}
        rendererSettings={{preserveAspectRatio: "xMidYMid slice"}}
        onComplete={() => scrollDown()}
      />
    </Holder>
  )
}

export default HomeHero;
