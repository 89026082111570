import React from 'react';
import PropTypes from 'prop-types';
import animationData from '../../assets/CoDevelop-Circle-Animation';
import darkAnimationData from '../../assets/CoDevelop-Circle-Animation-Positive-Transitions';
import Lottie from 'react-lottie-player';

function ProcessAnimation( { play, goTo, segment, rendererSettings, long, completeHandler } ) {
  return (
    <Lottie
      animationData={long ? darkAnimationData : animationData}
      play={play}
      goTo={goTo}
      loop={false}
      segments={segment}
      rendererSettings={rendererSettings}
      onComplete={() => {
        if( completeHandler ) { completeHandler(); }
      }}
    />
  )
}

ProcessAnimation.propTypes = {
  play: PropTypes.bool.isRequired,
  goTo: PropTypes.number,
  segment: PropTypes.array,
  rendererSettings: PropTypes.object,
  long: PropTypes.bool.isRequired,
  completeHandler: PropTypes.func,
};

ProcessAnimation.defaultProps = {
  play: false,
  rendererSettings: { preserveAspectRatio: "xMidYMid meet" },
  long: true,
  goTo: null,
};

export default ProcessAnimation;
