import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import GatsbyImage from 'gatsby-image';
import OpenIcon from '../../assets/open.inline.svg';

const Holder = styled.div`
  transition: color 0.25s ease-in-out;

  svg {
    path, line {
      transition: stroke 0.25s ease-in-out;
    }
  }

  .image-holder {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${props => props.theme.colours.green};
      z-index: 5;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
    }
  }

  &:hover {
    color: ${props => props.theme.colours.green};
    svg {
      path, line {
        stroke: ${props => props.theme.colours.green};
      }
    }
    .image-holder:after {
      opacity: 0.5;
    }
  }

  h2 {
    margin: 0 0 1rem 0;

    :first-letter { text-transform: capitalize; }
  }
`;

const ExcerptHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.8rem;
  grid-gap: 2rem;
  margin-top: 1rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    margin-top: 2rem;
  }

  p { margin: 0; }

  svg {
    margin-top: 0.3rem;
    width: 100%;
    height: auto;
    display: grid;
  }
`;

function Card({largeTitle, image, title, excerpt, slug, formattedTitle}) {
  return (
    <Holder className="card">
      <Link to={`/${slug}`}>
        {formattedTitle}
        {!formattedTitle && <h2 className={largeTitle ? 'h1' : undefined}>{title}</h2>}
        <div className="image-holder">
          <GatsbyImage
            alt={image.alt}
            fluid={image.fluid} />
        </div>
        <ExcerptHolder>
          {excerpt}
          <OpenIcon />
        </ExcerptHolder>
      </Link>
    </Holder>
  )
}

Card.propTypes = {
  largeTitle: PropTypes.bool.isRequired,
  image: PropTypes.object.isRequired,
  title: PropTypes.string,
  formattedTitle: PropTypes.object,
  excerpt: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
};

Card.defaultProps = {
  largeTitle: false
};

export default Card;
