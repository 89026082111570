import React from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';

const Holder = styled.div`
  padding: 3rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  align-items: center;
  @media( ${props => props.theme.breakpoints.md} ) { 
    padding: 4.8rem 0;
  }
  @media( ${props => props.theme.breakpoints.lg} ) { 
    grid-template-columns: 1fr 1fr;
  }
  > div {
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
`;

function TwoColumn( { children } ) {
  return (
    <Container>
      <Holder className="two-column-holder">
        {children}
      </Holder>
    </Container>
  )
}

export default TwoColumn;
