import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../../assets/menu.inline.svg';

const Holder = styled.div`
  position: relative;
  z-index: 100;
  svg {
    overflow: visible;
    line {
      stroke: ${props => props.navOpen ? props.theme.colours.white : props.theme.colours.green};
      transition: all 0.25s ease-in;
      &:first-child { 
        transform: ${props => props.navOpen ? 'rotate(45deg) translate(0, -1px)' : 'rotate(0deg) translate(0,0)'};
        transform-origin: top left;
      }
      &:nth-child(2) { 
        opacity: ${props => props.navOpen ? 0 : 1};
      }
      &:last-child { 
        transform: ${props => props.navOpen ? 'rotate(-45deg) translate(0, 1px)' : 'rotate(0deg) translate(0,0)'};
        transform-origin: bottom left;
      }
    }
  }
`;

function MenuIcon( { navOpen } ) {
  return (
    <Holder navOpen={navOpen}>
      <Icon/>
    </Holder>
  )
}

MenuIcon.propTypes = {
  navOpen: PropTypes.bool.isRequired,
};

export default MenuIcon;
