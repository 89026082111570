import React from 'react';
import Seo from '../components/molecules/Seo';
import ProcessShort from '../components/organisms/ProcessShort';
import HomeGallery from '../components/organisms/HomeGallery';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Container from '../components/atoms/Container';
import HomePageLinks from '../components/organisms/HomePageLinks';
import {RichText} from "prismic-reactjs";
import HomeHero from "../components/organisms/HomeHero";
import Testimonials from "../components/organisms/Testimonials";
import {withPrismicPreview} from "gatsby-plugin-prismic-previews";


const Holder = styled.div`
  background-color: ${props => props.theme.colours.grey};
  position: relative;
`;
const Text = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  padding: 5rem 0;
  @media( ${props => props.theme.breakpoints.md} ) { 
    grid-template-columns: 2fr 1fr;
    padding: 4rem 0;
  }
  @media( ${props => props.theme.breakpoints.lg} ) { 
    grid-template-columns: 1fr 1fr;
    padding: 6rem 0;
  }
  > div {
   > :first-child { margin-top: 0; }
   > :last-child { margin-bottom: 0; }
  }
`;

function IndexPage( { data } ) {
  const { gallery, links, process_heading, gallery_heading, intro, testimonials, testimonials_title } = data.prismicHomepage.data;
  const steps = data.prismicOurApproach.data.process;
  return (
    <>
      <Seo
        title="Co.Develop"
        meta={[{
          name: 'facebook-domain-verification',
          content: 'g64l5i20nnlc6zmd1kl5mz9kkpx9kv'
        }]}
      />
      <HomeHero/>
      <Holder>
        <HomeGallery gallery={gallery} text={gallery_heading}/>
        <Container>
          <Text>
            <div>
              <RichText render={intro.raw}/>
            </div>
          </Text>
        </Container>
        <ProcessShort steps={steps} heading={process_heading.text}/>
        <HomePageLinks links={links}/>
        {testimonials.length > 0 && <Testimonials testimonials={testimonials} heading={testimonials_title.text}/>}
      </Holder>
    </>
  )
};

export default withPrismicPreview(IndexPage);

export const pageQuery = graphql`
    query {
        prismicHomepage {
            _previewable
            data {
                intro {
                    raw
                }
                gallery_heading {
                    raw
                }
                gallery {
                    image {
                        alt
                        fluid(maxWidth: 4000) {
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                }
                process_heading {
                  text
                }
                links {
                    page {
                        id
                        slug
                        type
                    }
                    image {
                        alt
                        fluid(maxWidth: 1000) {
                            ...GatsbyPrismicImageFluid_noBase64
                        }
                    }
                    excerpt {
                        raw
                    }
                }
                testimonials {
                  title {
                    raw
                  }
                  text {
                    raw
                  }
                }
                testimonials_title {
                  text
                }
            }
        }
        prismicOurApproach {
            _previewable
            data {
                process {
                    step {
                        document {
                            ... on PrismicProcessStep {
                                id
                                data {
                                    short_title {
                                        text
                                    }
                                    short_text {
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
