import React from 'react';
import styled from 'styled-components';
import Container from "./Container";

const Holder = styled.div`
  padding: 3rem 0 1rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 4.8rem 0 2rem 0;
  }
  + div .two-column-holder {
    padding-top: 0;
  }
`;

function PageTitle({children}) {
  return (
    <Holder>
      <Container>
        <p className="small-sans-uppercase">{children}</p>
      </Container>
    </Holder>
  )
}

export default PageTitle;