import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { RichText } from "prismic-reactjs";
import PrismicRichText from "../atoms/PrismicRichText";

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  padding: 4rem 0;
  @media( ${props => props.theme.breakpoints.md} ) { 
    grid-template-columns: 1fr 1fr;
    align-items: start;
    padding: 4rem 0;
    min-height: 40vh;
  }
  &.testimonial-appear,
  &.testimonial-enter {
    position: absolute;
    opacity: 0;
  }
  &.testimonial-appear-active,
  &.testimonial-enter-active {
    position: absolute;
    opacity: 1;
    transition: opacity 250ms 250ms;
  }
  &.testimonial-appear-done,
  &.testimonial-enter-done {
    position: relative;
    opacity: 1;
  }
  &.testimonial-exit {
    opacity: 1;
  }
  &.testimonial-exit-active {
    opacity: 0;
    transition: opacity 250ms;
  }
  > div {
    > :first-child { margin-top: 0; }
    > :last-child { margin-bottom: 0; }
  }
`;

function Testimonial( { testimonial, show } ) {
  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      appear
      in={show}
      timeout={500}
      classNames="testimonial"
    >
      <Holder>
        <div className="apply-h1">
          <PrismicRichText render={testimonial.title.raw}/>
        </div>
        <div>
          <RichText render={testimonial.text.raw}/>
        </div>
      </Holder>
    </CSSTransition>
  )
}

Testimonial.propTypes = {
  testimonial: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
};

export default Testimonial;
