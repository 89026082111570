const linkResolver = (doc) => {
  // Pretty URLs for known types
  if (doc.type === 'news_post') return "/news/" + doc.uid;
  if (doc.type === 'project') return "/project/" + doc.uid;
  if (doc.type === 'our_approach') return "/our-approach";
  if (doc.type === 'about_us') return "/about-us";
  if (doc.type === 'new_page') return "/offices";
  if (doc.type === 'contact') return "/contact";
  if (doc.type === 'our_projects') return "/" + doc.uid;
  if (doc.type === 'homepage') return "/";
  if (doc.type === 'faqs') return "/faqs";
  if (doc.type === 'page') return "/" + doc.uid;
  // Backup for all other types
  return '/'
}

module.exports = linkResolver