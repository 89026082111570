import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Accordion from '../molecules/Accordion';
import { RichText } from 'prismic-reactjs';
import 'intersection-observer';
import ProcessAnimation from '../atoms/ProcessAnimation';
import { segments } from '../../data/global';
import AccordionGroup from '../molecules/AccordionGroup';
import PrismicRichText from "../atoms/PrismicRichText";

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 3rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    padding-bottom: 0;
    grid-template-columns: 1fr 2fr;
    grid-gap: 2rem;
  }
`;

const AnimationHolder = styled.div`
  display: none;
  position: relative;
  @media( ${props => props.theme.breakpoints.md} ) {
    display: block;
  }
  > div {
    position: sticky;
    top: 4.8rem;
  }
`;

const StepsHolder = styled.div`
  color: ${props => props.theme.colours.green};
  @media( ${props => props.theme.breakpoints.md} ) {
    padding-bottom: 7rem;
  }
  .small-sans-uppercase { 
    margin-bottom: 2rem;
  }
  .accordion-title { margin: 1rem 0 0.7rem 0; }
  .accordion-content { color: ${props => props.theme.colours.black}; }
  h3 {
    display: grid;
    align-items: start;
    grid-template-columns: 4rem 1fr;
    grid-gap: 1rem;
    overflow: visible;
    padding-bottom: 0.3rem;
    .title-number {
      line-height: 0.8;
    }
    .title {
      line-height: 0.87;
    }
    @media( ${props => props.theme.breakpoints.md} ) {
      .title {
        margin-top: 0.3rem;
      }
      @-moz-document url-prefix() {
        .title {
          margin-top: 0;
        }
      }
    }
  }
  h4 {
    ${props => props.theme.fluidType( 4.5 )};
    margin: 3rem 0 1rem 0;
  }
`;

function ProcessLong( { steps, heading } ) {

  // On page load everything is null
  const [ currentStep, setCurrentStep ] = useState( null );
  const [ segment, setSegment ] = useState( null );
  const [ outSegmentPlayed, setOutSegmentPlayed ] = useState( null );
  // And the starting frame is an inbetween position
  const [ loadFrame, setLoadFrame ] = useState( 75 );
  const [ play, setPlay ] = useState( false );

  // When the outSegmentPlayed or currentStep state changes we run this logic
  useEffect( () => {
    // If the out segment has played and we have a current step we can set the in segment.
    if ( outSegmentPlayed && currentStep !== null ) {
      // Play in animation (once out animation is finished)
      setSegment( segments.steps[ currentStep ].in );
      // On first load we have the animation paused so make sure it is playing.
      if( !play ) { setPlay(true) }
    }
  }, [ outSegmentPlayed, currentStep, play ] );

  // When an accordion is clicked we run this logic
  const accordionClickHandler = ( step ) => {
    // If the accordion clicked is not the same step we continue
    if ( step !== null && currentStep !== step ) {

      // If it is the first time anything has been clicked (we can test loadFrame for this)
      // we do the following...
      if ( loadFrame ) {
        // Remove the loadFrame
        setLoadFrame( null );
        // Set outSegmentPlayed to true (this is because we don't play an out segment on the first
        // click of a step
        setOutSegmentPlayed( true );
      } else {
        // Play out animation
        let outSegment = segments.steps[ currentStep ].out;
        // console.log( currentStep, step );
        setSegment( outSegment );
      }
      // We always set the current step
      setCurrentStep( step );

    }
  };

  return (
    <Holder>
      <AnimationHolder>
        <div>
          <ProcessAnimation
            light={false}
            segment={segment}
            play={play}
            goTo={loadFrame}
            completeHandler={() => setOutSegmentPlayed( !outSegmentPlayed )}/>
        </div>
      </AnimationHolder>
      <StepsHolder>
        <div className="small-sans-uppercase"><PrismicRichText render={heading.raw}/></div>
        <AccordionGroup clickHandler={( step ) => accordionClickHandler( step )}>
          {steps && steps.map( ( item, i ) =>
            <Accordion
              key={i}
              title={<h3 className="h1"><span className="title-number">0{i + 1}</span>
                <span className="title sans">{item.step.document.data.title.text}</span></h3>}
            >
              <RichText render={item.step.document.data.long_text.raw}/>
            </Accordion>
          )}
        </AccordionGroup>
      </StepsHolder>
    </Holder>
  )
}

ProcessLong.propTypes = {
  steps: PropTypes.array.isRequired,
  heading: PropTypes.object,
};

export default ProcessLong;
