import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import GalleryImages from '../molecules/GalleryImages';
import GalleryArrows from '../atoms/GalleryArrows';
import GalleryIndicators from '../atoms/GalleryIndicators';
import {useSwipeable} from "react-swipeable";

const Holder = styled.div`
  position: relative;
`;

const Images = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 60%;
`;

function Gallery( { gallery } ) {
  const [ current, setCurrent ] = useState( 0 );

  useEffect( () => {
    const timeOut = setTimeout( () => {
      if ( current === gallery.length - 1 ) {
        setCurrent( 0 )
      } else {
        setCurrent( current + 1 )
      }
    }, 4000 );
    return () => clearTimeout( timeOut );
  }, [ current, gallery.length ] );

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrent( current === gallery.length - 1 ? 0 : current + 1),
    onSwipedRight: () => setCurrent(current === 0 ? gallery.length - 1 : current -1),
  });

  if( gallery.length < 1 ) return null;

  return (
    <Holder>
      <Images {...handlers}>
        <GalleryImages images={gallery} current={current}/>
      </Images>
      <GalleryArrows clickHandler={( i ) => setCurrent( i )} images={gallery} current={current} hideMobile/>
      <GalleryIndicators clickHandler={(i) => setCurrent(i)} images={gallery} current={current} hideDesktop/>
    </Holder>
  )
}

Gallery.propTypes = {
  gallery: PropTypes.array.isRequired,
};

export default Gallery;
