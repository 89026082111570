  import React from 'react';
  import Seo from '../components/molecules/Seo';
  import {graphql} from 'gatsby';
  import styled from 'styled-components';
  import PageHeroImage from '../components/organisms/PageHeroImage';
  import {RichText} from 'prismic-reactjs';
  import TwoColumn from '../components/organisms/TwoColumn';
  import Container from '../components/atoms/Container';
  import Accordion from '../components/molecules/Accordion';
  import AccordionGroup from '../components/molecules/AccordionGroup';
  import PrismicRichText from "../components/atoms/PrismicRichText";
  import PageTitle from "../components/atoms/PageTitle";
  import Breadcrumbs from "../components/molecules/Breadcrumbs";
  import {withPrismicPreview} from "gatsby-plugin-prismic-previews";


  const Holder = styled.article`
    background-color: ${props => props.theme.colours.grey};
    padding-bottom: 4rem;
  
    .two-column-holder {
      align-items: start;
    }
  
    .plus-icon svg line {
      stroke: ${props => props.theme.colours.black};
    }
  `;

  const AnswerHolder = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    margin: 2rem 0;
    @media ( ${props => props.theme.breakpoints.md} ) {
      grid-template-columns: 2fr 1fr;
    }
    @media ( ${props => props.theme.breakpoints.lg} ) {
      grid-template-columns: 1fr 1fr;
    }
  `;


  function FaqPage({data}) {
    const {
      faq,
      hero,
      page_title,
      intro,
      strapline
    } = data.prismicFaqs.data;
    return (
      <>
        <Seo title={page_title ? page_title.text : 'Frequently Asked Questions'} />
        <PageHeroImage image={hero} />
        <Holder>
          <PageTitle>{page_title ? page_title.text : 'Frequently Asked Questions'}</PageTitle>
          <TwoColumn>
            <div>
              <RichText render={strapline.raw} />
            </div>
            <div>
              <PrismicRichText render={intro.raw} />
            </div>
          </TwoColumn>
          <Container>
            <AccordionGroup>
              {faq && faq.map((item, i) =>
                <Accordion
                  key={i}
                  title={<h2 className="p">Q: {item.question.text}</h2>}>
                  <AnswerHolder>
                    <div><PrismicRichText render={item.answer.raw} /></div>
                  </AnswerHolder>
                </Accordion>
              )}
            </AccordionGroup>
          </Container>
        </Holder>
        <Breadcrumbs bgColor="grey">
          {page_title ? page_title.text : 'Frequently Asked Questions'}
        </Breadcrumbs>
      </>
    )
  };

  export default withPrismicPreview(FaqPage);

  export const pageQuery = graphql`
      query {
          prismicFaqs {
              _previewable
              data {
                  page_title {
                      text
                  }
                  faq {
                      answer {
                          raw
                      }
                      question {
                          text
                      }
                  }
                  hero {
                      alt
                      fluid(maxWidth: 4000) {
                          ...GatsbyPrismicImageFluid_noBase64
                      }
                  }
                  strapline {
                      raw
                  }
                  intro {
                      raw
                  }
              }
          }
      }
  `;
