import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../atoms/Container';
import Card from '../molecules/Card';
import {RichText} from 'prismic-reactjs';
import {Link} from 'gatsby';
import PrismicRichText from "../atoms/PrismicRichText";

const Holder = styled.div`
  color: ${props => props.theme.colours.white};
  background-color: ${props => props.theme.colours.black};
  padding: 3rem 0;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: 4rem 0;
  }

  .heading {
    text-transform: uppercase;
    letter-spacing: 0.07em;
    line-height: 1.3;
    margin: 0;
    ${props => props.theme.fluidType(-1)};
  }

  .view-all {
    margin-top: 1rem;

    a { text-decoration: underline; }

    @media ( ${props => props.theme.breakpoints.md} ) {
      margin-top: 2rem;
    }
  }
`;

const PostHolder = styled.article`
  margin-top: 2rem;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  @media ( ${props => props.theme.breakpoints.md} ) {
    max-width: ${props => props.theme.typography.maxScreen}px;
    margin: 4rem auto 0 auto;
    padding: 0 4rem;
  }

  ul {
    list-style: none;
    margin: 0;
    display: flex;
    padding: 0 1.2rem 1rem 1.2rem;
    @media ( ${props => props.theme.breakpoints.md} ) {
      display: grid;
      padding: 0;
      grid-gap: 2rem;
      grid-template-columns: 1fr 1fr;
    }

    &:after {
      content: '';
      width: 1.2rem;
      display: block;
      flex-shrink: 0;
      @media ( ${props => props.theme.breakpoints.md} ) {
        display: none;
      }
    }

    li {
      display: inline-block;
      margin-right: 2rem;
      width: 80%;
      flex-shrink: 0;
      &:last-child { margin-right: 0; }
      @media ( ${props => props.theme.breakpoints.md} ) {
        display: block;
        margin-right: 0;
        width: 100%;
      }
    }
  }

  svg {
    path, line { stroke: ${props => props.theme.colours.white} }
  }
`;

function MoreNews({posts}) {
  return (
    <Holder>
      <Container>
        <h2 className="heading sans">More News</h2>
      </Container>
      <PostHolder>
        <ul>
          {posts && posts.map((post, i) =>
            <li key={i}>
              <Card
                image={post.data.preview_image}
                excerpt={<RichText render={post.data.excerpt.raw} />}
                slug={`news/${post.uid}`}
                formattedTitle={<PrismicRichText render={post.data.title.raw} />} />
            </li>
          )}
        </ul>
      </PostHolder>
      <Container>
        <p className="view-all"><Link to="/news">View all</Link></p>
      </Container>
    </Holder>
  )
}

MoreNews.propTypes = {
  posts: PropTypes.array.isRequired,
};

export default MoreNews;
