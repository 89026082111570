import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../atoms/Container';
import GatsbyImage from 'gatsby-image';
import FadeUpAnimation from '../molecules/FadeUpAnimation';


const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-content: start;
  margin-bottom: 2rem;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: 3.5rem;
  }
`;

const ImageHolder = styled.div`
  @media( ${props => props.theme.breakpoints.md} ) {
    &:nth-child(3n + 1) { 
      grid-column: ${props => props.primary ? '1/6' : '3/9'};
    }
    &:nth-child(3n + 2) { 
      margin-top: ${props => props.primary ? '40%' : '20%'};
      grid-column: ${props => props.primary ? '7/13' : '1/6'};
    }
    &:nth-child(3n) { 
      margin-top: ${props => props.primary ? '0' : '40%'};
      grid-column: ${props => props.primary ? '3/9' : '7/13'};
    }
  }
  p { color: ${props => props.theme.colours.green}; }
`;

function AboutGridImages( { images, primary } ) {
  return (
    <Container>
      <Holder>
        {images && images.map( ( image, i ) =>
          <ImageHolder key={i} primary={primary}>
            <FadeUpAnimation>
              <GatsbyImage fluid={image.image.fluid} alt={image.image.alt}/>
              {image.image.copyright && <p>{image.image.copyright}</p>}
            </FadeUpAnimation>
          </ImageHolder>
        )}
      </Holder>
    </Container>
  )
}

AboutGridImages.propTypes = {
  primary: PropTypes.bool,
  images: PropTypes.array.isRequired,
};

AboutGridImages.defaultProps = {
  primary: false
};

export default AboutGridImages;
