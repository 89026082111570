import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TwoColumn from './TwoColumn';
import Container from '../atoms/Container';
import GatsbyImage from 'gatsby-image';
import PrismicRichText from "../atoms/PrismicRichText";

const Holder = styled.div`
  background-color: ${props => props.theme.colours.green};
`;
const ImageHolder = styled.div`
  width: 100%;
`;

const List = styled.ul`
  list-style: none;
  padding: 2rem 0 3rem 0;
  margin: 0;
  @media( ${props => props.theme.breakpoints.md} ) { 
    columns: 2;
    column-gap: 2rem;
    > :first-child { margin-top: 0; }
  }
  li {
    margin: 0.2rem 0;
    display: grid;
    align-items: start;
    grid-template-columns: 1.5rem 1fr;
    grid-gap: 0.5rem;
    -webkit-column-break-inside: avoid;
  }
  .number {
    color: ${props => props.theme.colours.white};
  }
`;

function Sustainability( { text, image, opportunities, heading } ) {
  return (
    <Holder>
      <TwoColumn>
        <div className="apply-h1">
          <PrismicRichText render={heading.raw}/>
        </div>
        <div>
          <PrismicRichText render={text.raw}/>
        </div>
      </TwoColumn>
      <Container>
        <ImageHolder>
          <GatsbyImage alt={image.alt} fluid={image.fluid}/>
        </ImageHolder>
        <List>
          {opportunities && opportunities.map( ( opportunity, i ) =>
            <li key={i}>
              <span className="number">{i + 1 < 10 ? `0${i + 1}` : i + 1}</span><span>{opportunity.opportunity.text}</span>
            </li>
          )}
        </List>
      </Container>
    </Holder>
  )
}

Sustainability.propTypes = {
  text: PropTypes.object.isRequired,
  heading: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  opportunities: PropTypes.array.isRequired,
};

export default Sustainability;
