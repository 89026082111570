import React, { Component } from 'react';
import styled from 'styled-components';

const Holder = styled.div`
  width: 100%;
  max-width: ${props => props.theme.typography.maxScreen}px;
  margin: 0 auto;
  padding: 0 1.2rem;
  @media( ${props => props.theme.breakpoints.md} ) { 
    padding: 0 4rem;
  }
`;

class Container extends Component {
  render() {
    return (
      <Holder>
        {this.props.children}
      </Holder>
    )
  }
}

export default Container;
