import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Container from '../atoms/Container';
import Card from '../molecules/Card';
import { RichText } from 'prismic-reactjs';
import FadeUpAnimation from '../molecules/FadeUpAnimation';

const Holder = styled.div`
  background-color: ${props => props.theme.colours.grey};
  padding: 4rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 4rem 0;
  }
  @media( ${props => props.theme.breakpoints.lg} ) {
    padding: 6rem 0;
  }
`;

const Inner = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  align-content: start;
  @media( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-gap: 2rem;
  }
  @media( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: repeat(12, 1fr);
  }
  li {
    &:nth-child(even) { 
      margin-left: 12.5%;
    }
    &:nth-child(odd) { 
      margin-right: 12.5%;
    }
    @media( ${props => props.theme.breakpoints.md} ) {
      &:nth-child(even) { 
        margin-left: 0;
      }
      &:nth-child(odd) { 
        margin-right: 0;
      }
      &:nth-child(4n + 1),
      &:nth-child(4n + 2),
      &:nth-child(4n + 3),
      &:nth-child(4n) { 
        margin-top: 60%; 
        grid-row: span 2;
      }
      &:first-child { 
        margin-top: 0;
        grid-row: span 1; 
      }
    }
    @media( ${props => props.theme.breakpoints.lg} ) {
      &:nth-child(4n + 1) { grid-column: 2/7; }
      &:nth-child(4n + 2) { grid-column: 8/13; }
      &:nth-child(4n + 3) { grid-column: 1/6; }
      &:nth-child(4n) { grid-column: 7/12; }
    }
  }
`;

function HomePageLinks( { links } ) {

  return (
    <Holder>
      <Container>
        <Inner>
          {links && links.map( ( link, i ) =>
            <li key={i}>
              <FadeUpAnimation>
                <Card
                  largeTitle={true}
                  image={link.image}
                  excerpt={<RichText render={link.excerpt.raw}/>}
                  slug={link.page.slug}
                  title={link.page.type.replace( '_', ' ' )}/>
              </FadeUpAnimation>
            </li>
          )}
        </Inner>
      </Container>
    </Holder>
  )
}

HomePageLinks.propTypes = {
  links: PropTypes.array.isRequired
};

export default HomePageLinks;
